export const collectionOptions = {
  sokos: [
    { id: '', name: '' },
    { id: 'LOCALSTORE', name: 'Myymäläkeräys' },
    // { id: 'KTDC', name: 'Inex KTDC ' },
    { id: 'SIPOO_KV', name: 'Inex Kampanja' },
    { id: 'DARKSTORE', name: 'Inex Darkstore' },
    // { id: 'DIRECTDELIVERY', name: 'Suoratoimitus' },
    // { id: 'DC3', name: 'Inex DC3' },
    { id: 'SIPOO_SV', name: 'Sipoo SV' },
  ],
  prisma: [
    { id: '', name: '' },
    { id: 'LOCALSTORE', name: 'Myymäläkeräys' },
    { id: 'KTDC', name: 'Inex KTDC ' },
    // { id: 'SIPOO_KV', name: 'Inex Kampanja' },
    { id: 'DARKSTORE', name: 'Inex Darkstore' },
    { id: 'DIRECTDELIVERY', name: 'Suoratoimitus' },
    { id: 'DC3', name: 'Inex DC3' },
    // { id: 'SIPOO_SV', name: 'Sipoo SV' },
  ],
  terra: [
    { id: '', name: '' },
    // { id: 'LOCALSTORE', name: 'Myymäläkeräys' },
    // { id: 'KTDC', name: 'Inex KTDC ' },
    // { id: 'SIPOO_KV', name: 'Inex Kampanja' },
    // { id: 'DARKSTORE', name: 'Inex Darkstore' },
    // { id: 'DIRECTDELIVERY', name: 'Suoratoimitus' },
    // { id: 'DC3', name: 'Inex DC3' },
    // { id: 'SIPOO_SV', name: 'Sipoo SV' },
  ]
};

export const shippingMethodOptions = {
  sokos: [
    { id: '', name: '' },
    // { id: 'Ei rajoitettu', name: 'Ei rajoitettu' },
    // { id: 'Ei rajoitettu mittatietojen perusteella', name: 'Ei rajoitettu mittatietojen perusteella' },
    // { id: 'Myymälänouto', name: 'Myymälänouto' },
    // { id: 'Rahti', name: 'Rahti' },
    // { id: 'Suursäkki', name: 'Suursäkki' },
    // { id: 'Sähköinen', name: 'Sähköinen' },
    // { id: 'Tilaustuote', name: 'Tilaustuote' },
    // { id: 'Toimitusmyyntituote', name: 'Toimitusmyyntituote' },
  ],
  prisma: [
    { id: '', name: '' },
    // { id: 'Ei rajoitettu', name: 'Ei rajoitettu' },
    // { id: 'Ei rajoitettu mittatietojen perusteella', name: 'Ei rajoitettu mittatietojen perusteella' },
    // { id: 'Myymälänouto', name: 'Myymälänouto' },
    // { id: 'Rahti', name: 'Rahti' },
    // { id: 'Suursäkki', name: 'Suursäkki' },
    // { id: 'Sähköinen', name: 'Sähköinen' },
    // { id: 'Tilaustuote', name: 'Tilaustuote' },
    // { id: 'Toimitusmyyntituote', name: 'Toimitusmyyntituote' },
  ],
  terra: [
    { id: '', name: '' },
     { id: 'Ei rajoitettu', name: 'Ei rajoitettu' },
    // { id: 'Ei rajoitettu mittatietojen perusteella', name: 'Ei rajoitettu mittatietojen perusteella' },
    { id: 'Myymälänouto', name: 'Myymälänouto' },
    { id: 'Rahti', name: 'Rahti' },
    { id: 'Suursäkki', name: 'Suursäkki' },
    // { id: 'Sähköinen', name: 'Sähköinen' },
    { id: 'Tilaustuote', name: 'Tilaustuote' },
    { id: 'Toimitusmyyntituote', name: 'Toimitusmyyntituote' },
  ],
};

export const targetStoreOptions = {
  sokos: [
    { id: '', name: '' },
    { id: '518620703', name: 'Sokos Helsinki' },
    { id: '514390327', name: 'Sokos Satakunta Pori' },
  ],
  prisma: [
    { id: '', name: '' },
    { id: '513971200', name: 'Prisma Kaari Kannelmäki' },
    { id: '514390483', name: 'Prisma Pori Mikkola' },
  ],
  terra: [
    { id: '', name: '' },
  ],
};

export const visibleOptions = [
  { id: 'null', name: '' },
  { id: 'true', name: 'Kyllä' },
  { id: 'false', name: 'Ei' },
];

export const sellableOptions = [
  { id: 'null', name: '' },
  { id: 'true', name: 'Kyllä' },
  { id: 'false', name: 'Ei' },
];

export const storeNames = {
  sokos: 
    { id: 'sokos', name: 'Sokos' },
  prisma: 
    { id: 'prisma', name: 'Prisma' },
  terra: 
    { id: 'terra', name: 'Kodin Terra' },
};