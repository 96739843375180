import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Reports from './components/presentational/reports/Reports';
import Products from './components/presentational/products/Products';
import ControlInfo from './components/presentational/controlInfo/ControlInfo';
import NoMatch from './components/common/NoMatch';
import Logout from './auth/Logout';
import Login from './auth/Login';
import AuthRoute from './auth/AuthRoute';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/login" component={Login} />
      <AuthRoute exact path="/" component={ControlInfo} />
      <AuthRoute exact path="/tuotteet" component={Products} />
      <AuthRoute exact path="/tapahtumat" component={Reports} />
      <Route component={NoMatch} />
    </Switch>
  </BrowserRouter>
);
