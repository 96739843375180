import React from 'react';
import {
  TableCell,
  TableRow,
  TableHead,
  withStyles,
  TableBody,
  Table,
  Typography,
  DialogContent,
  Tooltip,
  IconButton
} from '@material-ui/core';

import MoreIcon from '@material-ui/icons/MoreVert';
import classnames from 'classnames';
import MessageIcon from '@material-ui/icons/Message';
import MUIDataTable from 'mui-datatables';

import isNil from 'lodash/isNil';

import {
  defineControlDataLevel,
} from '../../common/helper';

const styles = theme => ({
  chips: {
    marginRight: theme.spacing(0.5)
  },
  DisabledRow: {
    opacity: '.75',
    backgroundColor: 'rgba(0, 0, 0, .2)'
  },
  boxStyle: {
    backgroundColor: '#e0e0e0',
    fontSize: '0.8125rem',
    padding: '5px 8px',
    borderRadius: 16,
    display: 'inline-block',
    margin: '5px 5px 5px 0'
  },
  tooltip: {
    fontSize: 'inherit',
    minWidth: 400
  },
  title: {
    margin: '20px 0'
  }
});

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
};

const tableHeaders = [
  { name: 'levelString', label: 'Taso', options: { display: true, sort: true } },
  { name: 'idDescription', label: 'Tason kuvaus', options: { display: false, sort: true } },
  { name: 'sortObject', options: { display: 'excluded', filter: false } },
  { name: 'userName', label: 'Tekijä', options: { filter: true } },
  { name: 'rules', options: { display: 'excluded', filter: false } },
  { name: 'uniqueId', label: 'Yksilöivä id', options: { display: false, filter: false } },
  { name: 'comment', label: 'Kommentti', options: { display: true, filter: false } },
  { name: 'status', options: { display: 'excluded', filter: false } },
  {
    name: 'lastUpdated',
    label: 'Muokattu',
    options: {
      display: true,
      filter: false,
      customBodyRender: (value) => <p>{new Date(value).toLocaleDateString('fi-FI', dateOptions)}</p>
    }
  },
  {
    name: 'more',
    label: ' ',
    options: { display: true, filter: false, sort: false, searchable: false, viewColumns: false }
  }
];



const ProductInfoTable = props => {
  const {
    classes,
    data,
    setModifyControlData,
    openModal,
    includeControlDataRelations
  } = props;

  const {
    controlDataRelations,
    name,
    id,
    ean,
    brand
  } = data;

  return (
    <DialogContent>
      <Typography variant="h6" id="tableTitle" className={classes.title}>
        Tuoteinfo
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nimi</TableCell>
            <TableCell>Sok id</TableCell>
            <TableCell>EAN</TableCell>
            <TableCell>Brändi</TableCell>
            <TableCell>Kampanjat</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>{ean}</TableCell>
            <TableCell>{brand}</TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {!isNil(includeControlDataRelations) && includeControlDataRelations !== false && !isNil(controlDataRelations) ? (
        <>
          <Typography variant="h6" id="tableTitle" className={classes.title}>
            Liittyvät ohjaustiedot
          </Typography>
          <MUIDataTable
            data={controlDataRelations.map(item => {
              const sortStringOriginal = item.sort;
              const levelString = defineControlDataLevel(item.sort);
              return {
                sortStringOriginal,
                levelString,
                idDescription: item.idDescription,
                lastUpdated: item.lastUpdated,
                userName: item.userName,
                uniqueId: item.uniqueId,
                sort: item.sort,
                status: item.status,
                comment: item.comment && item.comment !== 'Ei kommenttia' ? (
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip
                    }}
                    title={item.comment}
                    placement="left"
                  >
                    <IconButton><MessageIcon color="primary" /></IconButton>
                  </Tooltip>
                ) : undefined,
                more: (
                  <IconButton style={{ padding: 8 }} onClick={() => {
                    setModifyControlData(item)
                    openModal(true)
                  }}>
                    <MoreIcon style={{ fontSize: 22 }} />
                  </IconButton>
                ),
              };
            })}
            columns={tableHeaders}
            options={{
              filter: false,
              print: false,
              download: false,
              search: false,
              selectableRows: 'none', // <===== will turn off checkboxes in rows
              responsive: 'stacked',
              setRowProps: row => {
                return {
                  className: classnames({
                    [classes.DisabledRow]: row[12] === 'disabled'
                  })
                }
              },
            }}
          />
        </>
      ) : null}
    </DialogContent>
  );
};

export default withStyles(styles)(ProductInfoTable);
