import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useAlert } from 'react-alert';

import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  DialogActions,
  TextField,
  CircularProgress,
  Box,
  MenuItem,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';
// import { DateTimePicker } from '@material-ui/pickers';

// import formatISO from 'date-fns/formatISO'

// Icons
import CloseIcon from '@material-ui/icons/Close';
// Files
import TextArea from '../../common/TextArea';

import ControlDataRuleRow from './ControlDataRuleRow';
import CustomChip from '../../common/CustomChip';
import { UPDATE_RULE, getProductsQuery } from '../../../queries'

const styles = () => ({
  datePicker: {
    width: '100%'
  },
  smallButton: {
    margin: '0 5px'
  },
  comment: {
    padding: 15,
    backgroundColor: 'rgba(0,0,0,.05)'
  },
  menuItem: {
    minHeight: 36
  }
});

const ControlDataModal = props => {
  const {
    modalOpen,
    handleModal,
    rowInfoData,
    classes,
    collection,
    shippingMethod,
    selectedChain,
    modalAdditionalInfoHeading,
    inputComment,
    newComment,
    setComment,
    query,
    visibleOptions,
    sellableOptions,
    targetStores,
    selectedTargetStore,
    setSelectedTargetStore,
  } = props;

  const [ loading, setLoading ] = useState(false);
  const [ selectedCollection, setCollection ] = useState('');
  const [ selectedShippingMethod, setShippingMethod ] = useState('');
  // const [ selectedStartDate, setSelectedStartDate ] = useState(new Date());
  // const [ selectedEndDate, setSelectedEndDate ] = useState(new Date());
  const [ visible, setVisible ] = useState('null');
  const [ sellable, setSellable ] = useState('null');
  const [ saldoLimit, setSaldoLimit ] = useState('');
  const [ editting, setEditting ] = useState({ edit: false, rowNr: null, rule: {} });
  const [ selectedEan, setSelectedEan ] = useState('');

  const rowData = rowInfoData.sort;
  const splittedRowData = rowData.split('#');
  const ean = splittedRowData[6];

  useEffect(() => {
    setSelectedEan(ean);
  }, [ean]);

  const queryWith = () => {
    const eanPart = selectedEan ? `ean:*${selectedEan}* ` : '';
    const queryString = eanPart;
    const splitted = queryString.split(',').join(' ') || ('*');
    console.log('using query: ', splitted);
    return splitted;
  };

  const checkInput = () => {
    if (selectedEan.length >= 10) {
      return false;
    }
    return true;
  };

  const { loading: loadingProducts, data: dataProducts } = useQuery(getProductsQuery, {
    variables: {
      query: queryWith(),
      from: 0,
      size: 1000,
      structureCategories: _.isEmpty(selectedChain) ? '' : selectedChain.structureId,
    },
    skip: checkInput()
  });

  const { uniqueId } = rowInfoData;

  const queryVariables = { chain: selectedChain.name, sort: rowInfoData.sort };

  const { loading: isLoading, data } = useQuery(query, {
    variables: queryVariables
  });

  const [ editControlDataMutation, { loading: mutateLoading } ] = useMutation(UPDATE_RULE);

  const handleOptionsChange = setter => event => {
    setter(event.target.value);
  };

  const alert = useAlert();

  const titles = [
    'Verkkonäkyvyys',
    'Myytävissä',
    'Toimitustapa',
    'Keräilymalli',
    'Saldoraja',
    'Saldorajan kohde',
    // 'Alkuaika',
    // 'Loppuaika',
    // 'Voimassa'
  ];

  // const dateOptions = {
  //   day: '2-digit',
  //   month: '2-digit',
  //   year: 'numeric',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit'
  // };

  const onClickUpdateRule = (mutateFunction, comment) => {
    const editedRule = [{
      // startDate: formatISO(new Date(selectedStartDate)),
      // endDate: formatISO(new Date(selectedEndDate)),
      visibility: visible.toString(),
      sellable: sellable.toString(),
      saldoLimit: saldoLimit !== '' ? saldoLimit : undefined,
      deliveryMethod: selectedShippingMethod || undefined,
      collectionModel: selectedCollection || undefined,
      saldoLimitTarget: !_.isEmpty(saldoLimit) ? selectedTargetStore : undefined,
    }];

    const input = {
      sort: rowInfoData.sort,
      // status: 'enabled',
      rules: JSON.stringify(editedRule),
      ...(newComment ? { comment: newComment } : { comment }),
      uniqueId
    }

    const options = {
      variables: { input },
      optimisticResponse: {
        __typename: "Mutation",
        updateEntity: {
          __typename: "Entity",
          sort: input.sort,
          // status: input.status,
          rules: input.rules,
          comment: input.comment,
          uniqueId
        }
      }
    };

    mutateFunction(options)
      .then(
        () => {
          alert.success('Ohjaussäännön rajaus päivitetty.');
          setLoading(false);
          setComment('');
        },
        (rejected) => {
          alert.error(`${rejected}`);
          setLoading(false);
        }
      )
      .catch((error) => {
        console.error('error modifying rule', error);
        setLoading(false);
      });
  };

  if (_.isEmpty(rowInfoData)) {
    return null;
  }

  const getControlDataItems = items => _.get(data, items);

  let rules = '';
  let savedComment = '';

  if (modalOpen) {
    rules = !_.isNil(getControlDataItems('queryControlData.Items')) && JSON.parse(getControlDataItems('queryControlData.Items').filter(x => x.uniqueId === uniqueId)[0].rules);
    savedComment = (!_.isNil(getControlDataItems('queryControlData.Items')) && getControlDataItems('queryControlData.Items').filter(x => x.uniqueId === uniqueId)[0].comment) || 'Ei kommenttia';
  }

  const resetModal = () => {
    handleModal(!modalOpen);
  };

  const renderVisibilityAndSellable = rule => {
    if (rule === true || rule === 'true') return 'Kyllä';
    if (rule === false || rule === 'false') return 'Ei';

    return '';
  };

  const showTargetName = id => {
    if (_.isNil(id)) return;

    const targetName = targetStores.filter(x => x.id === id);

    if (_.isNil(targetName[0])) return id;

    return targetName[0].name;
  };

  const renderEans = data => {
    if (data === undefined) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }
    if (!data.found.length) {
      return ( // if there is nothing found with the query
        <>
          No products found matching given eans
        </>
      )
    }
    if (data && data.found.length > 0) {
      return (
        <>
          <Typography style={{ marginTop: 20 }}>EAN:it</Typography>
          {data.found.map((item) => <CustomChip key={item.id} value={`${item.ean} : ${item.name}`} />)}
          {!_.isEmpty(data.notFound) && (
            <Typography style={{ marginTop: 20 }}>Ei löytyneet EAN:it</Typography>
          )}
          {data.notFound.map((item) => <CustomChip key={item} value={`${item}`} />)}
        </>
      );
    }
    return (
      <>
        No products found matching given eans
      </>
    )
  };

  const filteredQueryResults = data => {
    if (_.isNil(data)) return;

    const selectedEansArray = selectedEan.split(' ');
    // compare really found results with given eans, return only the found
    const reallyFoundEans = data.searchProducts.data.filter(product => selectedEansArray.includes(product.ean));
    // map the found eans to array of just eans. also map id's (sok id)
    const justFoundEans = reallyFoundEans.map(item => item.ean);

    // filter out which given eans were not found
    const notFoundEans = selectedEansArray.filter(targetEan => !justFoundEans.includes(targetEan))

    return { found: reallyFoundEans, notFound: notFoundEans };
  }

  return (
    <Dialog open={modalOpen} onClose={resetModal} scroll="paper" fullWidth maxWidth="lg">
      <Grid container>
        <Grid item xs={10}>
          <DialogTitle>
            Ohjaustiedon lisätiedot
            <div style={{ padding: '15px 0' }}>{modalAdditionalInfoHeading}</div>
            {renderEans(filteredQueryResults(dataProducts))}
          </DialogTitle>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={resetModal} style={{ marginTop: '12px', float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <div style={{ overflowX: 'auto' }}>
          <Table size="small" style={{ minWidth: 1200 }}>
            <TableHead style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
              <TableRow>
                {titles.map((item) => (
                  <TableCell align="right" key={item} style={{ borderBottom: '0', fontSize: 16 }}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.length && rules.map((rule, index) => {
                // const startDate = new Date(rule.startDate).toLocaleString('fi-FI', dateOptions);
                // const endDate = new Date(rule.endDate).toLocaleString('fi-FI', dateOptions);

                const currentEnoch = new Date();
                const startDateCompare = new Date(rule.startDate);
                const endDateCompare = new Date(rule.endDate);

                const enabled = !!(currentEnoch < endDateCompare && currentEnoch > startDateCompare);

                if (editting.edit && editting.rowNr === index) {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <TextField
                            // label="Verkkonäkyvyys"
                            select
                            value={visible}
                            onChange={handleOptionsChange(setVisible)}
                            style={{ width: '100%' }}
                          >
                            {visibleOptions.map(option => (
                              <MenuItem key={option.name} value={option.id} className={classes.menuItem}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            // label="Myytävissä"
                            select
                            value={sellable}
                            onChange={handleOptionsChange(setSellable)}
                            style={{ width: '100%' }}
                          >
                            {sellableOptions.map(option => (
                              <MenuItem key={option.name} value={option.id} className={classes.menuItem}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            // label="Toimitustapa"
                            select
                            value={selectedShippingMethod}
                            onChange={handleOptionsChange(setShippingMethod)}
                            style={{ width: '100%' }}
                          >
                            {shippingMethod.map(option => (
                              <MenuItem key={option.name} value={option.name} className={classes.menuItem}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            // label="Keräilymalli"
                            select
                            value={selectedCollection}
                            onChange={handleOptionsChange(setCollection)}
                            style={{ width: '100%' }}
                          >
                            {collection.map(option => (
                              <MenuItem key={option.name} value={option.name} className={classes.menuItem}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            // label="Saldoraja"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder="0"
                            value={saldoLimit}
                            type="number"
                            onChange={(event) => setSaldoLimit(event.target.value)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            // label="Saldorajan kohde"
                            select
                            value={selectedTargetStore}
                            onChange={handleOptionsChange(setSelectedTargetStore)}
                            style={{ width: '100%' }}
                          >
                            {targetStores.map(option => (
                              <MenuItem key={option.name} value={_.isEmpty(saldoLimit) ? '' : option.id} className={classes.menuItem} disabled={_.isEmpty(saldoLimit)}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        {/* <TableCell align="right">
                          <DateTimePicker
                            // label="Alkuaika"
                            className={classes.datePicker}
                            value={selectedStartDate}
                            onChange={setSelectedStartDate}
                            format="d. MMMM, y, HH:mm"
                            ampm={false}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <DateTimePicker
                            // label="Loppuaika"
                            className={classes.datePicker}
                            value={selectedEndDate}
                            onChange={setSelectedEndDate}
                            format="d. MMMM, y, HH:mm"
                            ampm={false}
                          />
                        </TableCell> */}
                        {/* <TableCell>
                          <TextField
                            // label="Voimassa"
                            fullWidth
                            value={enabled ? 'Kyllä' : 'Ei'}
                            type="text"
                          />
                        </TableCell> */}
                      </TableRow>
                      <TableRow>
                        {(loading || loadingProducts)&& <CircularProgress />}
                        {isLoading && <CircularProgress />}
                        {mutateLoading && <CircularProgress />}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12} style={{ padding: 0, border: 0 }}>
                          <DialogTitle style={{ padding: '16px 0' }}>Kommentti</DialogTitle>
                          <Box className={classes.comment}>{savedComment}</Box>
                          <Typography variant='h6' style={{ padding: '16px 0' }}>
                            Päivitä kommenttia
                          </Typography>
                          <TextArea
                            inputComment={inputComment}
                            comment={newComment}
                            setComment={setComment}
                          />
                          <Button
                            variant="contained"
                            className={classes.smallButton}
                            style={{ margin: '10px 10px 0 0' }}
                            onClick={() => {
                              setEditting({ edit: false, rowNr: null })
                              setComment('')
                              inputComment.current.value = ''
                            }}
                          >
                            Peruuta
                          </Button>
                          <Button
                            disabled={!_.isEmpty(saldoLimit) && _.isEmpty(selectedTargetStore)}
                            variant="contained"
                            color="primary"
                            className={classes.smallButton}
                            style={{ margin: '10px 0 0' }}
                            onClick={() => {
                              onClickUpdateRule(editControlDataMutation, savedComment)
                              setEditting({ edit: false, rowNr: null })
                              setLoading(true)
                            }}
                          >
                            Lähetä muutokset
                          </Button>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                }

                return (
                  <React.Fragment key={index}>
                    <ControlDataRuleRow status={enabled}>
                      <TableCell align="right" style={{ paddingTop: '20px', paddingBottom: '20px' }}>{renderVisibilityAndSellable(rule.visibility)}</TableCell>
                      <TableCell align="right">{renderVisibilityAndSellable(rule.sellable)}</TableCell>
                      <TableCell align="right">{rule.deliveryMethod}</TableCell>
                      <TableCell align="right">{rule.collectionModel}</TableCell>
                      <TableCell align="right">{rule.saldoLimit}</TableCell>
                      <TableCell align="right">{showTargetName(rule.saldoLimitTarget)}</TableCell>
                      {/* <TableCell align="right">{startDate}</TableCell>
                      <TableCell align="right">{endDate}</TableCell>
                      <TableCell align="right">{enabled ? 'Kyllä' : 'Ei'}</TableCell> */}
                    </ControlDataRuleRow>
                    <TableRow>
                      <TableCell colSpan={8} style={{ padding: 0, border: 0 }}>
                        <DialogTitle style={{ padding: '16px 0' }}>Kommentti</DialogTitle>
                        <Box className={classes.comment}>{savedComment}</Box>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.smallButton}
                          style={{ margin: '10px 0 0' }}
                          onClick={() => {
                            setEditting({ edit: true, rowNr: index });
                            setVisible(rule.visibility);
                            setSellable(rule.sellable);
                            setCollection(rule.collectionModel);
                            setShippingMethod(rule.deliveryMethod);
                            setSaldoLimit(rule.saldoLimit);
                            setSelectedTargetStore(rule.saldoLimitTarget);
                            // setSelectedEndDate(rule.endDate);
                            // setSelectedStartDate(rule.startDate);
                          }}
                        >
                          Päivitä ohjaussääntöä
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>

        </div>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default withStyles(styles)(ControlDataModal);
