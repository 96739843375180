import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Icons
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import { Typography, Hidden } from '@material-ui/core';

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  toolbarStuff: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

const ResponsiveDrawer = (props) => {
  const { classes, location, handleToggle, isOpen } = props;

  const drawer = (
    <div>
      <div
        className={classes.toolbar}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <img src="images/logo.png" alt="lalal" height="50" className={classes.toolbarStuff} />
        <Typography variant="h5" color="textSecondary">
          Verko
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItem component={Link} to="/" button selected={location.pathname === '/'}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Ohjaustiedot" />
        </ListItem>
        {/* <ListItem component={Link} to="/output" button selected={location.pathname === '/output'}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Ohjaustietojen output" />
        </ListItem> */}
        <ListItem component={Link} to="/tuotteet" button selected={location.pathname === '/tuotteet'}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Tuotteet" />
        </ListItem>
        {/* <ListItem component={Link} to="/tapahtumat" button selected={location.pathname === '/tapahtumat'}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Tapahtumat" />
        </ListItem> */}
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={handleToggle}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default withRouter(withStyles(styles)(ResponsiveDrawer));
