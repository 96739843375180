import React from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import MUIDataTable from 'mui-datatables';
import { getProductsQuery } from '../../../queries';
import CustomChip from '../../common/CustomChip';
import { defineControlDataLevel, createCategoryChip } from '../../common/helper';

const ControlDataProductModal = props => {
  const {
    productDialogOpen,
    handleCloseProductModal,
    productDialogData,
    selectedChain
  } = props;

  const alert = useAlert();

  const queryWith = sortObject => {
    const brandPart = !isEmpty(sortObject.brand) ? `brand:*${sortObject.brand}* ` : '';
    const campaignPart = !isEmpty(sortObject.campaign) ? `campaigns:*${sortObject.campaign}* ` : '';
    const eanPart = sortObject.ean ? `ean:*${sortObject.ean}* ` : '';
    const queryString = eanPart + brandPart + campaignPart;
    const splitted = queryString.split(',').join(' ') || ('*');

    return splitted;
  };

  const { loading, error, data } = useQuery(getProductsQuery, {
    variables: {
      query: queryWith(productDialogData),
      category: productDialogData.category,
      structureCategories: isEmpty(selectedChain) ? '' : selectedChain.structureId,
      from: 0,
      size: 1000,
      controlDataRelations: true,
    }
  });

  if (loading) {
    return (
      <Dialog open={productDialogOpen} fullWidth maxWidth="xs">
        <div style={{ textAlign: 'center', margin: 20 }}>
          <CircularProgress />
        </div>
      </Dialog>
    );
  }

  if (error) {
    alert.error(error);
  }

  const columns = [
    { name: 'id', options: { display: false, filter: false } },
    { name: 'ean', label: 'EAN', options: { filter: true } },
    { name: 'name', label: 'Nimi', options: { filter: true } },
    { name: 'name25', label: 'Pitkä nimi', options: { display: false, filter: true } },
    { name: 'description', label: 'Kuvaus', options: { display: false, filter: true } },
    { name: 'brand', label: 'Brändi', options: { filter: true } },
    { name: 'brandParent', label: 'Brändi parent', options: { display: false, filter: true } },
    { name: 'campaign', label: 'Kampanja', options: { filter: true } },
    { name: 'categories', label: 'Kategoriat', options: { filter: true, sort: false } },
    { name: 'controlDataRelations', label: 'Liittyvät ohjaustiedot', options: { display: false, filter: true, sort: false } }
  ];

  const options = {
    responsive: 'stacked',
    filter: true,
    print: false,
    download: false,
    selectableRows: 'none',
    filterType: 'dropdown',
    elevation: 1,
    expandableRows: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [ 25, 50, 100 ],
    textLabels: {
      body: {
        noMatch: 'Tuotteita ei löytynyt.',
        toolTip: 'Sort'
      },
      pagination: {
        next: 'Seuraava',
        previous: 'Edellinen',
        rowsPerPage: 'Tuotteita sivulla:',
        displayRows: 'of'
      },
      toolbar: {
        search: 'Etsi',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät kolumnit',
        filterTable: 'Filteröi tuotteita'
      },
      filter: {
        all: 'Kaikki',
        title: 'Filteröi',
        reset: 'Nollaa'
      },
      viewColumns: {
        title: 'Näytä kolumneissa',
        titleAria: 'Näytä / Piilota'
      },
      selectedRows: {
        text: 'EANia valittu',
        delete: 'Lisää ohjaussääntö valituille EANeille',
        deleteAria: 'Lisää ohjaussääntö valituille EANeille'
      }
    }
  };

  return (
    <Dialog open={productDialogOpen} onClose={handleCloseProductModal} fullWidth maxWidth="xl">
      <MUIDataTable
        title="Tuotteet"
        data={data.searchProducts.data.map(item => {
          return {
            id: item.id,
            brand: item.brand,
            brandParent: item.brandParent,
            campaign: item.campaigns
            .filter((camp) => camp !== null)
            .map(campaign => {
              return (
                <CustomChip
                  key={campaign.id}
                  value={`Id: ${campaign.id}`}
                />
              );
            }),
            ean: item.ean,
            name: item.name,
            name25: item.name25,
            description: item.description,
            controlDataRelations: item.controlDataRelations ? item.controlDataRelations
              .map((controlData) => {
                return defineControlDataLevel(controlData.sort, controlData.uniqueId);
              }) : null,
            categories: item.categories
              .filter((cat) => cat !== null)
              .sort((a, b) => {
                return a.level < b.level;
              })
              .map((category) => {
                return (
                  createCategoryChip(category.structureId, category.id, category.translations, category.level)
                );
              })
          };
        })}
        columns={columns}
        options={options}
      />
    </Dialog>
  );
};

export default ControlDataProductModal;
