import React from 'react';
import { Dialog } from '@material-ui/core';
import ProductInfoTable from './ProductInfoTable';

const ProductInfoModal = props => {
  const {
    open,
    close,
    productData,
    setModifyControlData,
    openModal,
    includeControlDataRelations
  } = props;

  return (
    <Dialog fullWidth open={open} onClose={close} scroll="paper" maxWidth="md">
      <ProductInfoTable data={productData} setModifyControlData={setModifyControlData} openModal={openModal} includeControlDataRelations={includeControlDataRelations} />
    </Dialog>
  );
};

export default ProductInfoModal;
