import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAlert } from 'react-alert';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getChains } from '../../queries';
import MultiSelect from './MultiSelect';

const ChainSelect = props => {
  const { loading, error, data } = useQuery(getChains);
  const { selectedChain, setChain, remember, resetForm } = props;
  const alert = useAlert();

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return alert.error(error);
  }

  return (
    <MultiSelect
      label="Ketju"
      disabled={loading}
      loading={loading}
      multiple={false}
      options={loading ? [] : data.getAllChains}
      selection={selectedChain}
      setSelection={setChain}
      remember={remember}
      resetForm={resetForm}
    />
  );
};

ChainSelect.propTypes = {
  selectedChain: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    structureId: PropTypes.string,
    __typename: PropTypes.string
  }).isRequired,
  setChain: PropTypes.func.isRequired
};

export default ChainSelect;
