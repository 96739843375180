import React, { useState } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import SystemEventsTable from './SystemEventsTable';
import EventsTable from './EventsTable';
import Navigation from '../navigation/Navigation';
import InfoToolTip from '../../common/InfoToolTip';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  appBarSpacer: theme.mixins.toolbar,
  card: {
    padding: theme.spacing(2)
  },
  button: {
    margin: '0 10px 24px 0'
  }
});

const Reports = props => {
  const [ systemEvent, setSystemEvent ] = useState(false);
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Grid container spacing={2} justify="flex-start">
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Typography variant="h6">Tapahtumat</Typography>
              <InfoToolTip text={
                <>
                  <p>Sivulla haetaan käyttäjien suorittamia ohjaustietojen luontiin, muokkaukseen ja poistoon liittyviä tapahtumia.</p>
                  <p>Ensiksi valitaan `Ketju`, minkä jälkeen hakuja voidaan suorittaa.</p>
                </>
              }/>
            </Grid>
          </Grid>
        </Grid>

        <Button size="small" variant="contained" className={classes.button} onClick={() => setSystemEvent(false)}>
          Tapahtumat
        </Button>
        <Button size="small" variant="contained" className={classes.button} onClick={() => setSystemEvent(true)}>
          Systeemitapahtumat
        </Button>

        {systemEvent ? <SystemEventsTable /> : <EventsTable /> }
      </main>
    </div>
  );
}

export default withRouter(withStyles(styles)(Reports));
