import React, { useState, useEffect, createRef } from 'react';
import { /*useQuery,*/ useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Grid,
  Paper,
  Typography,
  Fab,
  Container,
  Button,
  CssBaseline,
  CircularProgress,
  Box,
  // Divider,
  Grow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import Papa from 'papaparse';

// import formatISO from 'date-fns/formatISO'

import Navigation from '../navigation/Navigation';
import { saveControlData, getControlDataBySort, /* getChains */ } from '../../../queries';
import ControlDataTable from './ControlDataTable';
import ControlDataModal from './ControlDataModal';
import ProductDrawer from '../products/ProductDrawer';
import AddControlDataModal from './AddControlDataModal';
import AddEanControlDataModal from './AddEanControlDataModal';
import ChainSelect from '../../common/ChainSelect';
// import CampaignSelect from '../../common/CampaignSelect';
// import CategorySelect from '../../common/CategorySelect';
// import BrandSelect from '../../common/BrandSelect';
// import CategoryTreeSelect from '../../common/CategoryTreeSelect';
import CategoryModal from '../../common/CategoryModal';
import InfoToolTip from '../../common/InfoToolTip';
import { collectionOptions, shippingMethodOptions, visibleOptions, sellableOptions, targetStoreOptions } from '../../../data/ControlInfoOptions';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3)
  },
  appBarSpacer: theme.mixins.toolbar,
  card: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(1),
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  datePicker: {
    width: '100%'
  },
  fabWrapper: {
    position: 'relative',
  },
  fab: {
    top: -40,
    position: 'absolute',
    right: theme.spacing(2),
    margin: 0,
    zIndex: 199
  },
  paddingBox: {
    padding: '16px 0'
  },
  categoryBox: {
    padding: 16,
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  divider: {
    margin: '10px 0',
    background: 'none'
  },
  input: {
    display: 'none'
  },
  button: {
    margin: '16px 10px 20px 0'
  },
  fileUpload: {
    marginRight: 10
  }
});

const rememberSelection = type => JSON.parse(sessionStorage.getItem(type));

const ControlInfo = ({ classes, location }) => {
  const { state: routerState = { productEan: '' } } = location;
  // const profile = useContext(ProfileConsumer);
  const [ visible, setVisible ] = useState('');
  const [ sellable, setSellable ] = useState('');
  const [ saldoLimit, setSaldoLimit ] = useState('');
  const [ selectedTargetStore, setSelectedTargetStore ] = useState('');
  const [ selectedChain, setChain ] = useState(rememberSelection('controldata_chain') || {});
  const [ selectedCoop, setCoop ] = useState('');
  const [ selectedCampaign, setCampaign ] = useState(rememberSelection('controldata_campaign') || []);
  const [ selectedBrands, setBrands ] = useState(rememberSelection('controldata_brand') || []);
  const [ selectedCategories, setCategories ] = useState(rememberSelection('controldata_category') || []);
  const [ selectedCollection, setCollection ] = useState('');
  const [ selectedShippingMethod, setShippingMethod ] = useState('');
  // const [ selectedStartDate, setSelectedStartDate ] = useState(new Date());
  // const [ selectedEndDate, setSelectedEndDate ] = useState(new Date());
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ modalControlInfoData, setModalControlInfoData ] = useState({});
  const [ modalAdditionalInfoHeading, setModalAdditionalInfoHeading ] = useState('');
  const [ selectedEan, setSelectedEan ] = useState(rememberSelection('controldata_ean') || routerState.productEan);
  const [ newControlDataModalOpen, setNewControlDataModalOpen ] = useState(false);
  // const [ selectedCategoryTree, setSelectedCategoryTree ] = useState('');
  const [ categoryModalOpen, setCategoryModalOpen ] = useState(false);
  // const [ chains, setChains ] = useState([]);
  const [ categoryTree, setCagoryTree ] = useState('');
  const [ comment, setComment ] = useState('');
  const [ refetchQuery, setRefetchQuery ] = useState(0);
  const [ hasControlData, setHasControlData ] = useState(false);

  const chainName = selectedChain.name;
  const isEansSelected = _.size(selectedEan) >= 1

  // const { loading: loadingChains, error: errorChains, data: dataChains } = useQuery(getChains);

  const [ addControlData, { loading: loadingSaveControlData }] = useMutation(saveControlData);

  const inputComment = createRef();
  const fileInput = createRef();

  const alert = useAlert();

  const resetStorage = () => {
    const sessionStorageItems = [
      'controldata_chain',
      'controldata_campaign',
      'controldata_brand',
      'controldata_category',
      'controldata_ean',
    ];

    sessionStorageItems.forEach(item => {
      sessionStorage.removeItem(item);
    });
  };

  useEffect(() => {
    if (_.isEmpty(saldoLimit)) setSelectedTargetStore('');
  }, [saldoLimit]);

  // useEffect(() => {
  //   const CDTChain = [ 'CDT' ];

  //   if (dataChains && dataChains.getAllChains) {
  //     const chainIds = dataChains.getAllChains.map((x) => x.structureId);
  //     const updatedChainIds = CDTChain.concat(chainIds);
  //     setChains(updatedChainIds);
  //   }
  // }, [dataChains]);

  // if (errorChains) {
  //   return alert.error(errorChains);
  // }

  // const handleCategoryOpen = chain => {
  //   setCagoryTree(chain);
  //   setCategoryModalOpen(true);
  //   setSelectedCategoryTree(chain);
  // };

  const handleCategoryClose = () => {
    setCagoryTree('');
    setCategoryModalOpen(false);
  };

  const resetSelections = () => {
    resetStorage();
    setCoop('');
    setCampaign([]);
    setBrands([]);
    setCategories([]);
    setSelectedEan('');
    setChain({});
    // setSelectedCategoryTree('');
  };

  // const addDaysToDate = (days) => {
  //   const endDay = new Date();
  //   endDay.setDate(endDay.getDate() + days);
  //   return endDay;
  // };

  const resetValues = () => {
    // setSelectedEndDate(addDaysToDate(1));
    // setSelectedStartDate(new Date());
    setShippingMethod('');
    setCollection('');
    setVisible('');
    setSellable('');
    setSaldoLimit('');
    setSelectedTargetStore('');
  };

  const createSortString = () => {
    const sortValues = [
      selectedChain.name,
      selectedCoop,
      '', // location removed, but still supported.
      selectedBrands[0] ? selectedBrands[0].id : undefined,
      selectedCampaign[0] ? selectedCampaign[0].id : undefined,
      selectedCategories[0] ? selectedCategories[0].id : undefined,
      selectedEan
    ];
    const result = sortValues.map((value) => value || 'NA').join('#');
    // console.log('search sort string: ', result);
    return result.replace(/(#NA)*$/, '');
  };

  const handleMutate = (mutateFunction, newControlData) => {
    // console.log('saving new control data with params: ', JSON.stringify(newControlData, null, 2));
    const controlDataToSave = Object.assign({}, newControlData);
    const rules = JSON.stringify(newControlData.rules);
    controlDataToSave.rules = rules;
    const options = {
      variables: controlDataToSave,
      refetchQuery,
    }

    mutateFunction(options)
      .then(
        () => {
          alert.success('Lisätty uusi ohjaustieto.');
        },
        (rejected) => {
          alert.error(`${rejected.message.replace('GraphQL error: ', '')}`);
        }
      )
      .catch((error) => {
        alert.error(`${error}`);
      });
  };

  const handleSubmit = mutateFunction => async event => {
    event.preventDefault();
    setNewControlDataModalOpen(false);

    const newRule = {
      // startDate: formatISO(new Date(selectedStartDate)),
      // endDate: formatISO(new Date(selectedEndDate)),
      visibility: visible.id || 'null',
      sellable: sellable.id || 'null',
      saldoLimit: saldoLimit !== '' ? saldoLimit : undefined,
      deliveryMethod: selectedShippingMethod && selectedShippingMethod.name !== '' ? selectedShippingMethod.name : undefined,
      collectionModel: selectedCollection && selectedCollection.name !== '' ? selectedCollection.name : undefined
    };

    const rules = [].concat(newRule);

    // some attributes we save by id, this is to describe the id. For example category "StructureGroup_43495185455996"
    // means category WC ja kylpyhuone Kodin Terra
    let idDescription = '';

    if (selectedCategories[0]) {
      idDescription = selectedCategories[0].name;
    } else if (!_.isEmpty(selectedCampaign)) {
      idDescription = selectedCampaign[0].name;
    } else if (!_.isEmpty(selectedBrands)) {
        idDescription = selectedBrands[0].name;
    }

    const newControlData = {
      chain: selectedChain.name,
      coop: selectedCoop !== '' ? selectedCoop : undefined,
      unit: undefined,
      campaign: !_.isEmpty(selectedCampaign) ? selectedCampaign[0].id : undefined,
      brand: selectedBrands[0] ? selectedBrands[0].id : undefined,
      category: selectedCategories[0] ? selectedCategories[0].id : undefined,
      product: selectedEan !== '' ? selectedEan : undefined,
      idDescription: idDescription !== '' ? idDescription : undefined,
      rules,
      ...(inputComment.current.value && { comment: inputComment.current.value })
    };

    console.log('saving control data: ', newControlData);
    setComment('');
    await handleMutate(mutateFunction, newControlData);
  };

  const disabledBeforeChain = () => {
    if (_.isEmpty(selectedChain)) {
      return true;
    }
    return false;
  };

  const attributeDisable = (attributeName) => {
    if (!_.isEmpty(selectedCampaign) && attributeName !== 'selectedCampaign') {
      return true;
    }
    if (!_.isEmpty(selectedBrands) && attributeName !== 'selectedBrands') {
      return true;
    }
    if (!_.isEmpty(selectedCategories) && attributeName !== 'selectedCategories') {
      return true;
    }
    if (!_.isEmpty(selectedEan) && attributeName !== 'selectedEan') {
      return true;
    }
    return false;
  };

  const showAddControlInfoButton = () => {
    if (selectedCampaign[0] || selectedBrands[0] || selectedCategories[0] || isEansSelected) return true
    return false
  }

  const handleCSVImport = () => {
    const maxImportItems = 1000;
    const hasText = elem => elem === 'ean';
    const trimmer = data => _.map(data, _.trim);

    Papa.parse(fileInput.current.files[0], {
      skipEmptyLines: true,
      complete(results) {
        const trimmedHeader = trimmer(results.data[0].map((x) => x.toLowerCase().replace(/[^A-Za-z]/g, '')));
        const getHeaderEansColumn = trimmedHeader.findIndex(hasText);

        if (getHeaderEansColumn === -1) {
          alert.error(
            `CSV-tiedostosta puuttui otsikko ja / tai sarake EAN-tietueille tai otsikko oli väärässä muodossa (kirjoitusmuoto otsikolle: EAN, Ean tai ean).`
          );
          return false;
        }

        const trimmedEans = trimmer(results.data.map((x) => x[getHeaderEansColumn].replace(/[^0-9]/g, '')));
        const eans = trimmedEans;
        eans.shift();

        const eansWithOutEmptyRows = eans.filter(x => x);

        if (eansWithOutEmptyRows.length > maxImportItems) {
          alert.error(`Liian monta EAN-tietuetta löytyi. ${maxImportItems} on maksimi.`);
          return false;
        }

        const eanStrings = eansWithOutEmptyRows.join(' ');
        setSelectedEan(eanStrings);
        sessionStorage.setItem("controldata_ean", JSON.stringify(eanStrings))
        return true;
      },
      error(error, file) {
        console.log(`Error: ${error}, file: ${file}`);
      }
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <Navigation />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl">
            <Paper className={classes.card}>
              <form>
                <Grid container spacing={2} justify="flex-start">
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Typography variant="h6">Aseta ohjaustiedot</Typography>
                      <InfoToolTip text={
                        <>
                          <p>Sivulla voidaan etsiä, luoda, muokata ja poistaa ohjaustietoja eri hakuehdoilla.</p>
                          <p>Ensiksi valitaan `Ketju`, minkä jälkeen muut kentät aktivoituvat. Huomaa, että sivu suorittaa hakuja jo luoduista ohjaustiedoista, kun täytellään kenttiä.</p>
                          <p>`Ketju` on aina käytettävissä, mutta lopuista kentistä ainoastaan yksi on kerrallaan käytettävissä. Jos ohjaustietoa ei ole aikaisemmin luotu, plussa-ikonista pääsee luomaan sen erilaisilla ehdoilla.</p>
                          <p>Sivulla pystytään myös tarkastelemaan ohjaustietoon liittyviä tuotteita.</p>
                        </>
                      }/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.paddingBox}>
                      <ChainSelect
                        selectedChain={selectedChain}
                        setChain={setChain}
                        remember="controldata_chain"
                        resetForm={resetSelections}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <CampaignSelect
                      selectedChain={false}
                      selectedCampaign={selectedCampaign}
                      setCampaign={setCampaign}
                      disabled={disabledBeforeChain() || attributeDisable('selectedCampaign')}
                      remember="controldata_campaign"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocationSelect
                      selectedChain={selectedChain}
                      selectedCoop={selectedCoop}
                      selectedUnits={selectedUnits}
                      setUnits={setUnits}
                      disabled={disabledBeforeChain()}
                      remember="controldata_location"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.paddingBox}>
                      <BrandSelect
                        selectedChain={false}
                        selectedBrands={selectedBrands}
                        setBrands={setBrands}
                        disabled={disabledBeforeChain() || attributeDisable('selectedBrands')}
                        remember="controldata_brand"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.categoryBox}>
                      <CategorySelect
                        selectedCategories={selectedCategories}
                        setCategories={setCategories}
                        selectedChain={selectedChain}
                        disabled={disabledBeforeChain() || attributeDisable('selectedCategories')}
                        remember="controldata_category"
                      />
                      <Divider className={classes.divider} />
                      <CategoryTreeSelect
                        chains={chains}
                        chooseChain={handleCategoryOpen}
                        selectedCategoryTree={selectedCategories.length ? selectedCategoryTree : ''}
                        selectedChain={selectedChain}
                        disabled={disabledBeforeChain() || attributeDisable('selectedCategories')}
                      />
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Box className={classes.paddingBox}>
                      <ProductDrawer
                        selectedEan={selectedEan}
                        setSelectedEan={setSelectedEan}
                        disabled={disabledBeforeChain() || attributeDisable('selectedEan')}
                        remember="controldata_ean"
                      />
                      <label htmlFor="outlined-button-file">
                        <input
                          disabled={disabledBeforeChain() || attributeDisable('selectedEan')}
                          key={Date.now()}
                          className={classes.input}
                          type="file"
                          id="outlined-button-file"
                          name="outlined-button-file"
                          accept=".csv"
                          ref={fileInput}
                          onChange={handleCSVImport}
                        />
                        <Button variant="outlined" component="span" className={classes.button} disabled={disabledBeforeChain() || attributeDisable('selectedEan')}>
                          <AddCircleOutlineIcon fontSize="small" className={classes.fileUpload} />
                          Valitse EAN:t tiedostosta (CSV)
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <Button variant="contained" color="primary" onClick={() => resetSelections()}>
                      Tyhjennä valinnat
                    </Button>
                    <Grow in={hasControlData}>
                      <Button variant="contained" color="primary" style={{ marginLeft: '15px' }} onClick={() => {
                        setRefetchQuery(Date.now())
                      }}>
                        Päivitä lista
                      </Button>
                    </Grow>
                  </Grid>
                </Grid>
                {(isEansSelected && newControlDataModalOpen) ? (
                  <AddEanControlDataModal
                    open={newControlDataModalOpen}
                    close={() => {
                      setComment('');
                      setNewControlDataModalOpen(!newControlDataModalOpen)
                    }}
                    targetStores={targetStoreOptions[chainName]}
                    selectedTargetStore={selectedTargetStore}
                    setSelectedTargetStore={setSelectedTargetStore}
                    collection={collectionOptions[chainName]}
                    selectedCollection={selectedCollection}
                    setCollection={setCollection}
                    shippingMethod={shippingMethodOptions[chainName]}
                    selectedShippingMethod={selectedShippingMethod}
                    setShippingMethod={setShippingMethod}
                    visible={visible}
                    setVisible={setVisible}
                    visibleOptions={visibleOptions}
                    sellable={sellable}
                    setSellable={setSellable}
                    sellableOptions={sellableOptions}
                    saldoLimit={saldoLimit}
                    setSaldoLimit={setSaldoLimit}
                    // selectedEndDate={selectedEndDate}
                    // setSelectedEndDate={setSelectedEndDate}
                    // selectedStartDate={selectedStartDate}
                    // setSelectedStartDate={setSelectedStartDate}
                    selectedChain={selectedChain}
                    selectedEan={selectedEan}
                    resetValues={resetValues}
                    comment={comment}
                    setComment={setComment}
                    inputComment={inputComment}
                    setSelectedEan={setSelectedEan}
                    setRefetchQuery={setRefetchQuery}
                    setModalOpen={setNewControlDataModalOpen}
                  />
                ) : (
                  <AddControlDataModal
                    open={newControlDataModalOpen}
                    close={() => {
                      setComment('');
                      setNewControlDataModalOpen(!newControlDataModalOpen)
                    }}
                    collection={collectionOptions[chainName]}
                    selectedCollection={selectedCollection}
                    setCollection={setCollection}
                    shippingMethod={shippingMethodOptions[chainName]}
                    selectedShippingMethod={selectedShippingMethod}
                    setShippingMethod={setShippingMethod}
                    visible={visible}
                    setVisible={setVisible}
                    visibleOptions={visibleOptions}
                    sellable={sellable}
                    setSellable={setSellable}
                    sellableOptions={sellableOptions}
                    saldoLimit={saldoLimit}
                    setSaldoLimit={setSaldoLimit}
                    // selectedEndDate={selectedEndDate}
                    // setSelectedEndDate={setSelectedEndDate}
                    // selectedStartDate={selectedStartDate}
                    // setSelectedStartDate={setSelectedStartDate}
                    onClickSubmit={handleSubmit(addControlData)}
                    selectectedChain={selectedChain.name}
                    selectedCoop={selectedCoop}
                    selectedCampaign={selectedCampaign}
                    selectedBrands={selectedBrands}
                    selectedCategories={selectedCategories}
                    selectedEan={selectedEan}
                    resetValues={resetValues}
                    comment={comment}
                    setComment={setComment}
                    inputComment={inputComment}
                  />
                )}
              </form>
            </Paper>
            {categoryTree && (
              <CategoryModal
                open={categoryModalOpen}
                onClose={handleCategoryClose}
                setCategories={setCategories}
                structureId={categoryTree}
                remember="controldata_category"
              />
            )}
            <Box className={classes.fabWrapper}>
              <Grow in={showAddControlInfoButton()}>
                <Fab
                  color="secondary"
                  className={classes.fab}
                  onClick={() => {
                    if (isEansSelected) {
                      const trimmedEans = selectedEan.trim();
                      setSelectedEan(trimmedEans);
                    }
                    resetValues();
                    setNewControlDataModalOpen(true);
                  }}
                >
                  <AddIcon />
                </Fab>
              </Grow>
            </Box>
            {/* {loadingChains && <CircularProgress />}
            {errorChains && <Typography>Error: {errorChains}</Typography>} */}
            {loadingSaveControlData && <CircularProgress />}
            {/* {!loadingChains && ( */}
              <ControlDataTable
                modalOpen={modalOpen}
                handleModal={setModalOpen}
                setModalAdditionalInfoHeading={setModalAdditionalInfoHeading}
                rowInfoData={setModalControlInfoData}
                paper={1}
                selectedChain={selectedChain}
                querySortString={createSortString()}
                refetchQuery={refetchQuery}
                setHasControlData={setHasControlData}
                targetStores={targetStoreOptions[chainName]}
              />
            {/* )} */}
            {modalOpen && (
              <ControlDataModal
                modalOpen={modalOpen}
                handleModal={setModalOpen}
                modalAdditionalInfoHeading={modalAdditionalInfoHeading}
                rowInfoData={modalControlInfoData}
                selectedChain={selectedChain}
                collection={collectionOptions[chainName]}
                shippingMethod={shippingMethodOptions[chainName]}
                createSortString={createSortString()}
                inputComment={inputComment}
                newComment={comment}
                setComment={setComment}
                query={getControlDataBySort}
                visibleOptions={visibleOptions}
                sellableOptions={sellableOptions}
                targetStores={targetStoreOptions[chainName]}
                selectedTargetStore={selectedTargetStore}
                setSelectedTargetStore={setSelectedTargetStore}
              />
            )}
          </Container>
        </main>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(ControlInfo));
