import React, { useState } from 'react';

import ResponsiveDrawer from './ResponsiveDrawer';
import TopBar from './TopBar';

const Navigation = () => {
  const [ open, setOpen ] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <TopBar handleToggle={handleToggle} />
      <ResponsiveDrawer handleToggle={handleToggle} isOpen={open} />
    </div>
  );
};

export default Navigation;
