/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Treebeard } from 'react-treebeard';
import { useQuery } from '@apollo/react-hooks';
import { useAlert } from 'react-alert';
import { Grid, Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@material-ui/core';
import { getCategoryTree } from '../../queries';

const CategoryModal = props => {
  const {
    open,
    onClose,
    setCategories,
    structureId,
    remember
  } = props;

  const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(getCategoryTree, {
    variables: { id: structureId }
  });

  const [ data, setData ] = useState({});
  const [ cursor, setCursor ] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    if (dataCategories && dataCategories.getCategoryTree) {
      const modifiedTree = JSON.stringify(dataCategories).split(',"children":[]').join('');
      const parsedTree = JSON.parse(modifiedTree);

      const categoryTree = {
        "name": structureId,
        "toggled": true,
        "children": [
          ...parsedTree.getCategoryTree.children
        ]
      };

      setData(categoryTree);
    }
  }, [dataCategories])

  if (loadingCategories) {
    return <CircularProgress />;
  }

  if (errorCategories) {
    return alert.error(errorCategories);
  }

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
    }

    node.active = true;

    if (node.children) {
      node.toggled = toggled;
    }

    setCursor(node);
    setData(Object.assign({}, data));
  };

  const selectCategory = () => {
    const selectedItem = [{
      name: `Taso ${cursor.level} ${cursor.name} ${structureId}`,
      id: cursor.id
    }];

    if (cursor.name !== structureId) {
      onClose();
      setCategories(selectedItem);
      sessionStorage.setItem(remember, JSON.stringify(selectedItem));
    }
  };

  const treeStyles = {
    tree: {
      base: {
        fontFamily: 'inherit'
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Grid container justify="space-between" alignItems="center">
        <DialogTitle>Kategoriapuu</DialogTitle>
        <DialogContent>
          <Grid container justify="flex-end" alignItems="center">
            {(cursor.name && cursor.name !== structureId) && (
              <Button onClick={() => selectCategory()} variant="contained" color="primary">{cursor.name}</Button>
            )}
          </Grid>
        </DialogContent>
      </Grid>
      <Treebeard data={data} onToggle={onToggle} style={treeStyles} />
    </Dialog>
  );
};

export default CategoryModal;
