import _ from 'lodash';
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useQuery } from '@apollo/react-hooks';
import {
  CircularProgress,
  Paper,
  Grid,
  Select,
  MenuItem,
  withStyles,
  FormControl,
  InputLabel
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import CustomChipColored from '../../common/CustomChipColored';

import { defineControlDataLevelAsText } from '../../common/helper';
import { getEvents } from '../../../queries';


import ChainSelect from '../../common/ChainSelect';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(3)': {
          width: 200
        }
      }
    }
  },
  card: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(1),
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3)
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 150
  }
});

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
};

const columns = [
  { name: 'id', label: 'Ketju', options: { display: false } },
  {
    name: 'sort',
    label: 'Ohjaustiedon taso',
    options:{
      filter: false,
      customBodyRender: value => {
        const { sort, uniqueId } = value;

        try {
          const split = sort.split('#');
          const chainLevel = split[0];
          const toimipisteLevel = split[2];
          const campaignLevel = split[4];
          const brandLevel = split[3];
          const categoryLevel = split[5];
          const eanLevel = split[6];

          return (
            <React.Fragment key={uniqueId}>
              <CustomChipColored value={`Ketju: ${chainLevel}`} type="chain" />
              {!isEqual(toimipisteLevel, 'NA') && (
                <CustomChipColored
                  value={`Toimipiste: ${toimipisteLevel}`}
                  type="location"
                />
              )}
              {!isEqual(campaignLevel, 'NA') && (
                <CustomChipColored
                  value={`Kampanja: ${campaignLevel}`}
                  type="campaign"
                />
              )}
              {!isEqual(brandLevel, 'NA') && (
                <CustomChipColored value={`Brändi: ${brandLevel}`} type="brand" />
              )}
              {!isEqual(categoryLevel, 'NA') && (
                <CustomChipColored
                  value={`Kategoria: ${categoryLevel}`}
                  type="category"
                />
              )}
              {!isEqual(eanLevel, 'NA') && (
                <CustomChipColored value={`EAN: ${eanLevel}`} type="ean" />
              )}
            </React.Fragment>
          );
        } catch (err) {
          // console.log('Error defining control data level with sort: ', sort);
        }

        return null;
      }
    }
  },
  { name: 'level', label: 'Ohjaustiedon taso teksti', options: { display: false, filter: true } },
  { name: 'message', label: 'Viesti', options: { display: true, filter: true } },
  {
    name: 'timestamp',
    label: 'Aika',
    options: {
      display: true,
      filter: true,
      customBodyRender: value => (
        <p>{new Date(value).toLocaleDateString('fi-FI', dateOptions)}</p>
      ),
      sortDirection: 'desc'
    }
  },
  { name: 'type', label: 'Tyyppi' },
  { name: 'responsible', label: 'Muokkaaja' }
];

const EventsTable = ({ classes }) => {
  const [limit, setLimit] = useState(25); // page size
  // const [nextToken, setNextToken] = useState('');
  const [selectedChain, setChain] = useState({});

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const { loading, error, data } = useQuery(getEvents, {
    fetchPolicy: 'cache-first',
    variables: { chain: selectedChain.name, limit: limit === 0 ? null : limit },
    skip: _.isEmpty(selectedChain) // if no chain selection, skip the query and dont render table
  });

  const options = {
    filterType: 'dropdown',
    filter: true,
    print: false,
    download: true,
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    expandableRows: false,
    responsive: 'stacked',
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    searchPlaceholder: 'Hae tyypillä tai muokkaajalla',
    textLabels: {
      body: {
        noMatch: 'Ei näytettävää.',
        toolTip: 'Lajittele'
      },
      pagination: {
        next: 'Seuraava',
        previous: 'Edellinen',
        rowsPerPage: 'Tapahtumia sivulla:',
        displayRows: '/'
      },
      toolbar: {
        search: 'Etsi',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät kolumnit',
        filterTable: 'Filteröi tapahtumia'
      },
      filter: {
        all: 'Kaikki',
        title: 'Filteröi',
        reset: 'Nollaa'
      },
      viewColumns: {
        title: 'Näytä kolumneissa',
        titleAria: 'Näytä / Piilota'
      },
      selectedRows: {
        text: 'riviä valittu',
        delete: 'Poista',
        deleteAria: 'Poista valitut rivit'
      }
    },
  };

  if (loading) {
    return (
      <main>
        <CircularProgress />
      </main>
    );
  }

  if (error) return `error: ${error}`;

  return (
    <Grid container>
      <Paper className={classes.card}>
        <Grid container spacing={4}>
          <FormControl className={classes.formControl}>
            <ChainSelect
              selectedChain={selectedChain}
              setChain={setChain}
              remember="controldata_chain"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="limit-label">Tapahtumien määrä</InputLabel>
            <Select
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={0}>Kaikki</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Paper>
      { data ? (
        <>
      <Grid item xs={12}>
        <MUIDataTable
          title="Tapahtumat"
          columns={columns}
          data={data.queryEventDataByChain.Items.map(item => {
            return {
              id: item.chain,
              sort: item,
              level: defineControlDataLevelAsText(item.sort),
              responsible: item.userName,
              timestamp: item.timestamp,
              type: item.action,
              message: item.message
            };
          })}
          options={options}
        />
      </Grid>
      </>
      ) : null }
    </Grid>
  );
};

export default withStyles(styles)(EventsTable);
