import React from 'react';
import { MuiThemeProvider, createMuiTheme, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/Done';
import Routes from './routes';
import ProfileProvider from './auth/ProfileProvider';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    type: 'light',
    secondary: {
      main: '#74187e'
    },
    primary: {
      main: '#227e18'
    }
  },
  overrides: {
    MUIDataTableToolbarSelect: {
      root: {
        flex: 1
      }
    }
  }
});

// optional configuration
const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 10000,
  offset: '1px',
  transition: transitions.FADE,
  containerStyle: {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    zIndex: 10000,
    maxWidth: '100vw'
  }
};

const iconSyle = {
  fontSize: 20,
  opacity: 0.9,
  marginRight: theme.spacing(1)
};

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div style={style}>
      <Snackbar open style={{ width: '100vw' }}>
        <SnackbarContent
          style={
            (options.type === 'error' && { backgroundColor: theme.palette.error.dark }) ||
            (options.type === 'success' && { backgroundColor: green[600] })
          }
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {options.type === 'error' && <ErrorIcon style={iconSyle} />}
              {options.type === 'success' && <SuccessIcon style={iconSyle} />}
              {message}
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={close}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </div>
  );
};

const App = () => {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <MuiThemeProvider theme={theme}>
        <ProfileProvider>
          <Routes />
        </ProfileProvider>
      </MuiThemeProvider>
    </AlertProvider>
  );
};

export default App;
