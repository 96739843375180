import { withStyles } from '@material-ui/styles';
import React from 'react';

import { TableRow } from '@material-ui/core';

const ControlDataRow = withStyles({
  root: {
    background: 'rgba(0, 0, 0, .2)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48
    // padding: '0 30px',
  }
  // disable label because of an error?
  /* label: {
    textTransform: 'capitalize',
  }, */
})(TableRow);

const ControlDataRuleRow = ({ status, children }) => {
  if (status) {
    return <TableRow>{children}</TableRow>;
  }
  return <ControlDataRow>{children}</ControlDataRow>;
};

export default ControlDataRuleRow;
