import React, { Fragment } from 'react';
import { Box, withStyles } from '@material-ui/core';
import debounce from 'lodash/debounce';

const styles = () => ({
  textarea: {
    width: '100%',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: 5
  }
});

const TextArea = props => {
  const {
    classes,
    inputComment,
    comment,
    setComment
  } = props;

  const debouncedComment = debounce(value => {
    setComment(value);
  }, 500);

  return (
    <Fragment>
      <textarea
        ref={inputComment}
        onChange={event => debouncedComment(event.target.value)}
        className={classes.textarea}
        placeholder="Kommenttikenttä. Max 500 merkkiä."
        maxLength={500}
        rows={5}
      />
      <Box>
        {500 - comment.length} merkkiä jäljellä.
      </Box>
    </Fragment>
  );
};

export default withStyles(styles)(TextArea);
