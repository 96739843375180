import gql from 'graphql-tag';

export const getControlDataBySort = gql`
  query queryControlData($chain: String!, $sort: String, $limit: Int, $nextToken: String) {
    queryControlData(chain: $chain, filter: { sort: $sort }, limit: $limit, nextToken: $nextToken) {
      Items {
        id
        sort
        uniqueId
        owner
        lastUpdated
        rules
        comment
        idDescription
        status
        integrationStatus
        userName
      }
      LastEvaluatedKey
      Info
    }
  }
`;

export const getEvents = gql`
  query queryEventDataByChain($chain: String!, $limit: Int, $nextToken: String )  {
    queryEventDataByChain(chain: $chain, limit: $limit, nextToken: $nextToken)  {
      Items {
        id
        chain
        timestamp
        userName
        sort
        action
        controlDataUniqueId
        message
        # oldRules
        # newRules // add if needed
      }
      LastEvaluatedKey
    }
  }
`;

export const getSystemEvents = gql`
  query querySystemEventData {
    querySystemEventData {
      Items {
        id
        timestamp
        action
        message
      }
      LastEvaluatedKey
    }
  }
`;

// export const saveControlData = gql`
//   mutation createEntity(
//     $start: String!
//     $end: String!
//     $chain: String
//     $coop: String
//     $unit: String
//     $campaign: String
//     $category: String
//     $brand: String
//     $controlData: AWSJSON = "{}"
//   ) {
//     createEntity(
//       input: {
//         id: "OHJAUSTIETO"
//         startDate: $start
//         endDate: $end
//         aok: $coop
//         ketju: $chain
//         toimipiste: $unit
//         kampanja: $campaign
//         brandi: $brand
//         kategoria: $category
//         ohjaustieto: $controlData
//       }
//     ) {
//       id
//       sort
//       status
//       startDate
//       endDate
//       lastUpdated
//       lastExecuted
//       owner
//       ohjaustieto
//     }
//   }
// `;

export const saveControlData = gql`
  mutation(
    $chain: String!
    $coop: String
    $unit: String
    $campaign: String
    $brand: String
    $category: String
    $product: String
    $idDescription: String
    $comment: String
    $rules: AWSJSON = "{}"
  ) {
    createEntity(
      input: {
        id: "OHJAUSTIETO"
        chain: $chain
        aok: $coop
        location: $unit
        campaign: $campaign
        brand: $brand
        category: $category
        product: $product
        idDescription: $idDescription
        comment: $comment
        rules: $rules
      }
    ) {
      sort
      id
      uniqueId
      status
      integrationStatus
      lastUpdated
      lastExecuted
      idDescription
      owner
      rules
      userName
      comment
    }
  }
`;

export const updateControlData = gql`
  mutation updateEntity($id: String, $sort: String, $status: String, $comment: String) {
    updateEntity(input: { id: "", sort: $sort, status: $status, comment: $comment }) {
      id
      sort
      status
      comment
    }
  }
`;

export const deleteControlData = gql`
  mutation DeleteEntity($id: String!, $sort: String!) {
    deleteEntity(input: { id: $id, sort: $sort }) {
      id
      sort
    }
  }
`;

export const getBrandsByName = gql`
  query getBrandsByName($name: String = "*") {
    searchBrands(query: $name, size: 25, from: 0) {
      data {
        id
        nameFin
        nameEng
      }
    }
  }
`;

export const getUnitsByName = gql`
  query getUnitsByName($coopId: String, $chainId: String, $name: String) {
    searchBusinessPlaces(aokId: $coopId, chainId: $chainId, name: $name) {
      data {
        id
        name
      }
    }
  }
`;

export const getChains = gql`
  query getAllChains {
    getAllChains {
      id
      name
      structureId
    }
  }
`;

export const getCoops = gql`
  query getAllAoks {
    getAllAoks {
      id
      name
    }
  }
`;

export const getCampaignsByName = gql`
  query getCampaignsByName($name: String = "*", $size: Int) {
    searchCampaigns(query: $name, size: $size) {
      data {
        id
        name: PromDesc
      }
    }
  }
`;

export const getProductsQuery = gql`
  query getProducts($query: String = "*", $category: String, $size: Int, $from: Int, $structureCategories: String, $controlDataRelations: Boolean) {
    searchProducts(
      query: $query
      queryCategories: $category
      size: $size
      from: $from
      structureCategories: $structureCategories
      controlDataRelations: $controlDataRelations
    ) {
      data {
        id
        name
        name25
        ean
        description
        brand
        brandName
        brandParent
        ecomPublish
        campaigns {
          id
        }
        categories {
          id
          level
          structureId
          translations {
            name
            description
            language
          }
        }
        controlDataRelations {
          sort
          status
          lastUpdated
          lastExecuted
          idDescription
          userName
          uniqueId
          comment
          rules
        }
      }
    }
  }
`;

export const getProductQuery = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      ean
      name25
      description
      brand
      brandParent
    }
  }
`;

export const getCategoryById = gql`
  query getCategoryById($id: ID!) {
    getCategory(id: $id, key: "id") {
      id
      nameFin
    }
  }
`;

export const getCategoriesByName = gql`
  query getCategoriesByName($name: String = "*", $filters: String) {
    searchCategories(query: $name, structureCategories: $filters, size: 25, from: 0) {
      data {
        id
        nameFin
        parent
        level
        structureId
      }
    }
  }
`;

export const getProductControlData = gql`
  query getProductControlData($id: String!) {
    getProductControlData(product: $id) {
      Items {
        id
        sort
        owner
        lastUpdated
        rules
        status
        useName
      }
    }
  }
`;

export const createEntityForEans = gql`
  mutation CreateEntityForEans($input: CreateEntityForEansInput!) {
    createEntityForEans(input: $input) {
      Items {
        sort
        uniqueId
      }
    }
  }
`;

export const getCategoryTree = gql`
  query getCategoryTree($id: String = "*") {
    getCategoryTree(structureId: $id) {
      children {
        id
        name: nameFin
        level
        children {
          id
          name: nameFin
          level
          children {
            id
            name: nameFin
            level
            children {
              id
              name: nameFin
              level
              children {
                id
                name: nameFin
                level
                children {
                  id
                  name: nameFin
                  level
                  children {
                    id
                    name: nameFin
                    level
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_RULE = gql`
  mutation deleteRule($sort: String!, $ruleId: String!) {
    deleteRule(input: { sort: $sort, ruleId: $ruleId }) {
      id
      sort
    }
  }
`;

export const ADD_RULE = gql`
  mutation addRule($sort: String!, $rule: AWSJSON!) {
    addRule(input: { sort: $sort, rule: $rule }) {
      id
    }
  }
`;

export const UPDATE_RULE = gql`
  mutation UpdateEntity($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      uniqueId
      sort
      # status
      comment
      rules
    }
  }
`;

export const CONTROLDATA_SUBSCRIPTION = gql`
  subscription controlDataUpdated {
    onCreateEntity {
      id
      sort
      uniqueId
      owner
      lastUpdated
      rules
      status
      integrationStatus
      comment
      idDescription
      userName
    }
  }
`;

export const GET_CONTROLDATA_BY_ID = gql`
  query getControlDataById($uniqueId: String!) {
    getControlDataById(uniqueId: $uniqueId) {
      Items {
        id
        sort
        owner
        lastUpdated
        rules
        status
        idDescription
        comment
        userName
        uniqueId
      }
    }
  }
`;

export const DELETE_MULTIPLE_ENTITY = gql`
  mutation deleteMultipleEntity($input: DeleteMultipleEntityInput!) {
   deleteMultipleEntity(input: $input) {
      Items {
        id
        sort
      }
    }
  }
`;
