import React from 'react';
import { withStyles, Box } from '@material-ui/core';

const styles = () => ({
  boxStyle: {
    backgroundColor: '#e0e0e0',
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    color: 'inherit',
    padding: '5px 8px',
    borderRadius: 16,
    display: 'inline-block',
    margin: '5px 5px 5px 0'
  },
  bigText: {
    fontSize: '1rem'
  }
});

const CustomChip = props => {
  const { value, classes, bigText } = props;

  return (
    <Box className={`${classes.boxStyle} ${bigText ? classes.bigText : ''}`}>{value}</Box>
  );
};

export default withStyles(styles)(CustomChip);
