import React, { Fragment, createRef, /*useState,*/ useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import {
  withStyles,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import CategorySelect from '../../common/CategorySelect';
//import BrandSelect from '../../common/BrandSelect';
import ChainSelect from '../../common/ChainSelect';
//import CategoryTreeSelect from '../../common/CategoryTreeSelect';
//import CampaignSelect from '../../common/CampaignSelect';
import InfoToolTip from '../../common/InfoToolTip';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    flexGrow: 1
  },
  card: {
    padding: theme.spacing(1)
  },
  button: {
    margin: '16px 10px 20px 0'
  },
  paddingBox: {
    padding: '16px 0'
  },
  categoryBox: {
    padding: 16,
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  input: {
    display: 'none'
  },
  fileUpload: {
    marginRight: 10
  }
});

const SearchGrid = props => {
  // const [ filters, setFilters ] = useState('');

  const {
    classes,
    name,
    ean,
    // selectedCategories,
    // setCategories,
    // selectedBrands,
    // setBrands,
    selectedChain,
    setChain,
    // chains,
    // chooseChain,
    resetSelection,
    // setName,
    setEan,
    // accurateSearch,
    // setAccurateSearch,
    // selectedCategoryTree,
    // selectedCampaign,
    // setCampaign,
    includeControlDataRelations,
    setIncludeControlDataRelations,
    setInputName,
    setInputEan
  } = props;

  const disabledBeforeChain = () => {
    if (_.isEmpty(selectedChain)) {
      return true;
    }
    return false;
  };

  const fileInput = createRef();
  const alert = useAlert();
  const maxImportItems = 1000;

  const hasText = (elem) => elem === 'ean';
  const trimmer = (data) => _.map(data, _.trim);

  const handleCSVImport = () => {
    Papa.parse(fileInput.current.files[0], {
      skipEmptyLines: true,
      complete(results) {
        const trimmedHeader = trimmer(results.data[0].map((x) => x.toLowerCase().replace(/[^A-Za-z]/g, '')));
        const getHeaderEansColumn = trimmedHeader.findIndex(hasText);

        if (getHeaderEansColumn === -1) {
          alert.error(
            `CSV-tiedostosta puuttui otsikko ja / tai sarake EAN-tietueille tai otsikko oli väärässä muodossa (kirjoitusmuoto otsikolle: EAN, Ean tai ean).`
          );
          return false;
        }

        const trimmedEans = trimmer(results.data.map((x) => x[getHeaderEansColumn].replace(/[^0-9]/g, '')));
        const eans = trimmedEans;
        eans.shift();

        const eansWithOutEmptyRows = eans.filter(x => x);

        if (eansWithOutEmptyRows.length > maxImportItems) {
          alert.error(`Liian monta EAN-tietuetta löytyi. ${maxImportItems} on maksimi.`);
          return false;
        }

        const eanStrings = eansWithOutEmptyRows.join(' ');
        setEan(eanStrings);
        setInputEan(eanStrings);
        sessionStorage.setItem("products_ean", JSON.stringify(eanStrings))
        return true;
      },
      error(error, file) {
        console.log(`Error: ${error}, file: ${file}`);
      }
    });
  };

  const updateSearchValue = useCallback(_.debounce((value, setter) => {
    setter(value);
  }, 500), []);

  useEffect(() => {
    setInputName(name);
    setInputEan(ean);
  }, []);

  return (
    <Fragment>
      <Grid container className={classes.grid} spacing={2} justify="flex-start">
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Typography variant="h6">Hae tuotteita</Typography>
            <InfoToolTip text={
              <>
                <p>Sivulla haetaan tuotteita eri hakuehdoin.</p>
                <p>Ensiksi valitaan `Ketju`, minkä jälkeen muut kentät aktivoituvat ja hakuja voidaan suorittaa.</p>
              </>
            }/>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.paddingBox}>
            <ChainSelect
              selectedChain={selectedChain}
              setChain={setChain}
              remember="products_chain"
              resetForm={false}
            />
          </Box>
        </Grid>
        {/*
        <Grid item xs={12} md={4}>
          <TextField
            placeholder="Etsi tuotteita..."
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Tuotteen nimi"
            id="nimi"
            value={name}
            onChange={e => {
              sessionStorage.setItem("products_name", JSON.stringify(e.target.value))
              setName(e.target.value)
              updateSearchValue(e.target.value, setInputName)
            }}
            disabled={disabledBeforeChain()}
            remember="products_name"
          />
          <FormControlLabel
            disabled={disabledBeforeChain()}
            control={<Checkbox checked={accurateSearch[0].checked} onChange={setAccurateSearch('name')} color="primary" />}
            label="Tarkka haku"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CampaignSelect
            selectedChain={false}
            selectedCampaign={selectedCampaign}
            setCampaign={setCampaign}
            disabled={disabledBeforeChain()}
            remember="products_campaign"
          />
          <FormControlLabel
            disabled={disabledBeforeChain()}
            control={<Checkbox checked={accurateSearch[1].checked} onChange={setAccurateSearch('campaign')} color="primary" />}
            label="Tarkka haku"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.paddingBox}>
            <BrandSelect
              selectedBrands={selectedBrands}
              setBrands={setBrands}
              multiple
              selectedChain={false}
              disabled={disabledBeforeChain()}
              remember="products_brand"
            />
            <FormControlLabel
              disabled={disabledBeforeChain()}
              control={<Checkbox checked={accurateSearch[2].checked} onChange={setAccurateSearch('brand')} color="primary" />}
              label="Tarkka haku"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.categoryBox}>
            <CategorySelect
              selectedCategories={selectedCategories}
              setCategories={setCategories}
              checkboxes
              disabledCheckboxes={disabledBeforeChain()}
              disabled={disabledBeforeChain() || !filters}
              filters={filters}
              setFilters={setFilters}
              remember="products_category"
            />
            <CategoryTreeSelect
              chains={chains}
              chooseChain={chooseChain}
              selectedCategoryTree={selectedCategoryTree}
              disabled={disabledBeforeChain()}
            />
          </Box>
        </Grid>
        */}
        <Grid item xs={12} md={4}>
          <Box className={classes.paddingBox}>
            <TextField
              placeholder="Etsi EAN:ta..."
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="EAN"
              id="ean"
              value={ean}
              onChange={e => {
                sessionStorage.setItem("products_ean", JSON.stringify(e.target.value))
                setEan(e.target.value)
                updateSearchValue(e.target.value, setInputEan);
              }}
              disabled={disabledBeforeChain()}
              remember="products_ean"
            />
            <label htmlFor="outlined-button-file">
              <input
                disabled={disabledBeforeChain()}
                key={Date.now()}
                className={classes.input}
                type="file"
                id="outlined-button-file"
                name="outlined-button-file"
                accept=".csv"
                ref={fileInput}
                onChange={handleCSVImport}
              />
              <Button variant="outlined" component="span" className={classes.button} disabled={disabledBeforeChain()}>
                <AddCircleOutlineIcon fontSize="small" className={classes.fileUpload} />
                Valitse EAN:t tiedostosta (CSV)
              </Button>
            </label>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={2} justify="flex-start">
        <Grid item xs={2}>
        <Box className={classes.paddingBox}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={includeControlDataRelations} onChange={() => {
                sessionStorage.setItem("products_connections", JSON.stringify(!includeControlDataRelations))
                setIncludeControlDataRelations(!includeControlDataRelations)
              }}/>}
              label="Sisällytä ohjaustietoliitokset"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={2} justify="flex-start">
        <Grid item xs={4}>
            <Button variant="contained" color="primary" onClick={() => resetSelection()}>
              Tyhjennä valinnat
            </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const ProductSearch = (props) => {
  const {
    classes,
    name,
    ean,
    noPaper,
    selectedBrands,
    setBrands,
    selectedCategories,
    setCategories,
    selectedChain,
    setChain,
    chains,
    chooseChain,
    resetSelection,
    setName,
    setEan,
    accurateSearch,
    setAccurateSearch,
    selectedCategoryTree,
    selectedCampaign,
    setCampaign,
    includeControlDataRelations,
    setIncludeControlDataRelations,
    setInputName,
    setInputEan
  } = props;

  if (noPaper) {
    return (
      <div className={classes.root}>
        <SearchGrid
          classes={classes}
          name={name}
          ean={ean}
          selectedCategories={selectedCategories}
          setCategories={setCategories}
        />
      </div>
    );
  }

  return (
    <Paper className={classes.root}>
      <SearchGrid
        classes={classes}
        name={name}
        ean={ean}
        selectedCategories={selectedCategories}
        setCategories={setCategories}
        selectedBrands={selectedBrands}
        setBrands={setBrands}
        selectedChain={selectedChain}
        setChain={setChain}
        chains={chains}
        chooseChain={chooseChain}
        resetSelection={resetSelection}
        setName={setName}
        setEan={setEan}
        accurateSearch={accurateSearch}
        setAccurateSearch={setAccurateSearch}
        selectedCategoryTree={selectedCategoryTree}
        selectedCampaign={selectedCampaign}
        setCampaign={setCampaign}
        includeControlDataRelations={includeControlDataRelations}
        setIncludeControlDataRelations={setIncludeControlDataRelations}
        setInputName={setInputName}
        setInputEan={setInputEan}
      />
    </Paper>
  );
};

export default withStyles(styles)(ProductSearch);
