import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, Container } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import ProductList from './ProductList';
import Navigation from '../navigation/Navigation';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3)
  },
  appBarSpacer: theme.mixins.toolbar
});

const Products = (props) => {
  const { classes } = props;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <Navigation />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl">
            <ProductList />
          </Container>
        </main>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(Products));
