import React from 'react';
import { withStyles, Box } from '@material-ui/core';

const styles = () => ({
  boxStyleRoot: {
    backgroundColor: '#c7c7c7',
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    color: 'inherit',
    padding: '5px 8px',
    borderRadius: 16,
    display: 'inline-block',
    margin: '5px 5px 5px 0',
  },
  boxStyleLocation: {
    backgroundColor: '#edebeb',
  },
  boxStyleBrand: {
    backgroundColor: '#ededdf',
  },
  boxStyleCampaign: {
    backgroundColor: '#dfede6',
  },
  boxStyleCategory: {
    backgroundColor: '#dfe8ed',
  },
  boxStyleEan: {
    backgroundColor: '#eddfe4',
  },
  boxStyleCategoryCDT: {
    backgroundColor: '#d5dbd3',
  },
  boxStyleCategoryPrisma: {
    backgroundColor: '#258749',
  },
  boxStyleCategoryTerra: {
    backgroundColor: '#37BA59',
  },
  boxStyleCategorySokos: {
    backgroundColor: '#052A42',
  },
  bigText: {
    fontSize: '1rem'
  }
});

const CustomChipColored = props => {
  const { value, classes, bigText, type } = props;

  switch(type) {
    case 'chain':
      return (<Box className={`${classes.boxStyleRoot} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'location':
      return(<Box className={`${classes.boxStyleRoot} ${classes.boxStyleLocation} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'brand':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleBrand} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'campaign':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCampaign} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'category':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCategory} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'ean':
    return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleEan} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'categoryCDT':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCategoryCDT} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'categoryPrisma':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCategoryPrisma} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'categoryTerra':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCategoryTerra} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    case 'categorySokos':
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleCategorySokos} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
    default:
      return (<Box className={`${classes.boxStyleRoot} ${classes.boxStyleRoot} ${bigText ? classes.bigText : ''}`}>{value}</Box>)
  }
};

export default withStyles(styles)(CustomChipColored);
