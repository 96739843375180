import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { storeNames } from '../../data/ControlInfoOptions';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  selection: {
    fontSize: '1rem',
    marginTop: '-27px',
    paddingRight: 30
  },
  menuItem: {
    minHeight: 36
  }
});

const MultiSelect = props => {
  const {
    classes,
    disabled,
    label,
    noLabel,
    options,
    selection,
    setSelection,
    multiple,
    chains,
    categoryTree,
    selectedChain,
    remember,
    resetForm
  } = props;

  const handleChange = event => {
    if (resetForm) resetForm();
    if (remember) sessionStorage.setItem(remember, JSON.stringify(event.target.value));
    setSelection(event.target.value);
  };

  const getStoreName = (name) => {
    let storeName = '';
    if(storeNames !== undefined && name !== undefined){
      storeName = storeNames[name].name
    } else {
      storeName = name;
    }
    return storeName;
  };

  return (
    <FormControl disabled={disabled} className={classes.formControl}>
      {!noLabel &&
        <InputLabel shrink>
          {label}
        </InputLabel>
      }
      <Select
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        multiple={multiple}
        input={<Input name={label} id={label} />}
        onChange={handleChange}
        disabled={disabled}
        value={remember ? '' : selection}
      >
        {selectedChain && (
          <MenuItem key={selectedChain.id} value={selectedChain.structureId} name={selectedChain.structureId} className={classes.menuItem}>
            {selectedChain.structureId}
          </MenuItem>
        )}
        {!selectedChain && categoryTree
          ? chains.map(chain => (
            <MenuItem key={chain} value={chain} name={chain} className={classes.menuItem}>
              {chain}
            </MenuItem>
          ))
          : options.map(option => (
            <MenuItem key={option.id} value={option} name={option.name} className={classes.menuItem}>
              {label === 'Ketju' ? getStoreName(option.name) : option.name}
            </MenuItem>
          ))
        }
      </Select>
      {remember && <Box className={classes.selection}>{label === 'Ketju' ? getStoreName(selection.name) : selection.name}</Box>}
    </FormControl>
  );
};

MultiSelect.defaultProps = {
  multiple: false
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiple: PropTypes.bool
};

export default withStyles(styles)(MultiSelect);
