import React from 'react';
import CustomChipColored from '../common/CustomChipColored';

import { isEqual } from 'lodash';

export function defineControlDataLevel(sort, uniqueId) {
  try {
    const split = sort.split('#');
    const chainLevel = split[0];
    const toimipisteLevel = split[2];
    const campaignLevel = split[4];
    const brandLevel = split[3];
    const categoryLevel = split[5];
    const eanLevel = split[6];

    return (
      <React.Fragment key={uniqueId}>
        <CustomChipColored value={`Ketju: ${chainLevel}`} type="chain" />
        {!isEqual(toimipisteLevel, 'NA') && (
          <CustomChipColored
            value={`Toimipiste: ${toimipisteLevel}`}
            type="location"
          />
        )}
        {!isEqual(campaignLevel, 'NA') && (
          <CustomChipColored
            value={`Kampanja: ${campaignLevel}`}
            type="campaign"
          />
        )}
        {!isEqual(brandLevel, 'NA') && (
          <CustomChipColored value={`Brändi: ${brandLevel}`} type="brand" />
        )}
        {!isEqual(categoryLevel, 'NA') && (
          <CustomChipColored
            value={`Kategoria: ${categoryLevel}`}
            type="category"
          />
        )}
        {!isEqual(eanLevel, 'NA') && (
          <CustomChipColored value={`EAN: ${eanLevel}`} type="ean" />
        )}
      </React.Fragment>
    );
  } catch (err) {
    // console.log('Error defining control data level with sort: ', sort);
  }

  return null;
}

export function defineControlDataLevelAsText(sort) {
  let returnString = '';
  try {
    const split = sort.split('#');
    // const chainLevel = split[0];
    const toimipisteLevel = split[2];
    const campaignLevel = split[4];
    const brandLevel = split[3];
    const categoryLevel = split[5];
    const eanLevel = split[6];

    if(!isEqual(toimipisteLevel, 'NA')) {
      returnString += `Toimipiste: ${toimipisteLevel}`
    }

    if(!isEqual(campaignLevel, 'NA')) {
      returnString += `Kampanja: ${campaignLevel}`;
    }

    if(!isEqual(brandLevel, 'NA')) {
      returnString += `Brändi: ${brandLevel}`;
    }

    if(!isEqual(categoryLevel, 'NA')) {
      returnString += `Kategoria: ${categoryLevel}`;
    }

    if(!isEqual(eanLevel, 'NA')) {
      returnString += `EAN: ${eanLevel}`;
    }
  } catch (err) {
    // console.log('Error defining control data level with sort: ', sort);
  }

  return returnString;
}

export function createCategoryChip(
  structureId,
  categoryId,
  translation,
  level
) {
  const translationsFin = translation.reduce((accum, value) => {
    if(value.language === 'fin') {
      return value.name
    }
    return '';
  }, '');

  try {
    switch (structureId) {
      case 'PRISMA':
        return (
          <CustomChipColored
            type="categoryPrisma"
            key={categoryId}
            value={`${structureId} ID: ${categoryId} Nimi: ${translationsFin} Taso: ${level}`}
          />
        );
      case 'KodinTerra':
        return (
          <CustomChipColored
            type="categoryTerra"
            key={categoryId}
            value={`${structureId} ID: ${categoryId} Nimi: ${translationsFin} Taso: ${level}`}
          />
        );
      case 'Sokos':
        return (
          <CustomChipColored
            type="categorySokos"
            key={categoryId}
            value={`${structureId} ID: ${categoryId} Nimi: ${translationsFin} Taso: ${level}`}
          />
        );
      default:
        return (
          <CustomChipColored
            type="categoryCDT"
            key={categoryId}
            value={`${structureId} ID: ${categoryId} Nimi: ${translationsFin} Taso: ${level}`}
          />
        );
    }
  } catch (err) {
    console.log('Error creating chip: ', err);
  }

  return null;
}
