import jwtDecode from 'jwt-decode';
import React from 'react';

const ProfileContext = React.createContext();

export const ProfileConsumer = ProfileContext;

const ProfileProvider = (props) => {
  const rawProfile = localStorage.getItem('id_token');
  // const { data, loading } = useQuery(getChains);
  // const { getAllChains } = data;
  const { children } = props;

  let profile = '';
  if (rawProfile) {
    profile = jwtDecode(rawProfile);
  }
  /* const chain = _.find(getAllChains, { name: profile['custom:chain'] });

  if (loading) {
    return null;
  } */

  const updatedValues = {
    username: profile.email ? profile.email : profile['cognito:username'],
    chain: profile['custom:chain']
    // chainId: chain.id
  };
  return <ProfileContext.Provider value={updatedValues}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
