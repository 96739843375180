const Logout = () => {
  sessionStorage.clear();
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
  window.location = process.env.REACT_APP_LOGOUT_URL;
  return null;
};

export default Logout;
