import { ApolloClient, HttpLink, from, InMemoryCache, defaultDataIdFromObject, Observable } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import authMiddleware from './auth/AuthMiddleware';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL
});

const getNewToken = async refreshToken => {
  const tokenUrl = process.env.REACT_APP_TOKEN_URL;
  const clientIdParam = new URLSearchParams(process.env.REACT_APP_LOGIN_URL).get('client_id');

  const postData = {
    grant_type: 'refresh_token',
    client_id: clientIdParam,
    refresh_token: refreshToken
  };

  let formBody = [];

  Object.keys(postData).forEach(key => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(postData[key]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  formBody = formBody.join('&');

  const response = await fetch(tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: formBody
  });

  if (response.status === 200) {
    const data = await response.json();
    localStorage.setItem('access_token', data.access_token);
    return data;
  }

  throw new Error('ERROR REFRESHING TOKEN', response.status);
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors[0].message === 'Token has expired.') {
    const refreshToken = localStorage.getItem('refresh_token');

    if (refreshToken) {
      return new Observable(observer => {
        getNewToken(refreshToken)
          .then(refreshResponse => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: refreshResponse.access_token,
              }
            }))
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            }

            // Retry last failed request
            forward(operation).subscribe(subscriber)
          })
          .catch(error => {
            // No refresh or client token available, we force user to login
            observer.error(error)
          })
      })
    }
  }

  if (networkError && networkError.statusCode === 401) {
    window.location = '/logout';
  }

  return null;
});

const cacheOptions = {
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'Entity':
        // console.log(`handling caching for control data entity: ${object.uniqueId}${object.sort}`);
        return `${object.uniqueId}`;
      case 'Event':
        // console.log('handling caching for event : ', JSON.stringify(object, null, 2));
        return `${object.id}`;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
  // freezeResults: true,
};

const kuukkeliApolloClient = new ApolloClient({
  connectToDevTools: true,
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(cacheOptions),
  clientState: {
    defaults: {
      uiColorPalette: 'light'
    },
  }
});

export default kuukkeliApolloClient;
