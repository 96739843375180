import React, { useState } from 'react';
import { Drawer, withStyles, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProductSearch from './ProductSearch';
import ProductTable from './ProductTable';

const styles = () => ({
  drawer: {
    minWidth: '100%'
  },
  field: {
    width: '100%'
  },
  closeIcon: {
    float: 'right'
  }
});

const ProductDrawer = (props) => {
  const { classes, selectedEan, setSelectedEan, disabled, remember } = props;
  const [ open, setOpen ] = useState(false);
  const [ name, setName ] = useState('');
  const [ ean, setEan ] = useState('');
  const [ brandName, setBrandName ] = useState('');
  const [ categories, setCategories ] = useState('');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleChange = event => {
    if (remember) sessionStorage.setItem(remember, JSON.stringify(event.target.value));
    setSelectedEan(event.target.value);
  };

  return (
    <React.Fragment>
      <TextField
        placeholder="Etsi EAN:ta..."
        disabled={disabled}
        fullWidth
        id="ean"
        label="EAN"
        className={classes.field}
        value={selectedEan}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        /* InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Avaa hakupaneeli">
                <div>
                  <IconButton onClick={toggleDrawer} disabled={disabled}>
                    <SearchIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </InputAdornment>
          )
        }} */
      />

      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <div className={classes.drawer}>
          <IconButton className={classes.closeIcon} onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <ProductSearch noPaper name={setName} ean={setEan} brandName={setBrandName} categories={setCategories} />
          <ProductTable
            name={name}
            ean={ean}
            brandName={brandName}
            categories={categories}
            selectedProduct={setSelectedEan}
            closeDrawer={toggleDrawer}
          />
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default withStyles(styles)(ProductDrawer);
