import React, { useState, useEffect, Fragment, forwardRef } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import MUIDataTable from 'mui-datatables';
import { useAlert } from 'react-alert';
import { CircularProgress, Typography, withStyles, IconButton, Tooltip, Button, ClickAwayListener, Box, TableRow, TableBody, TableCell, Select, MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import classnames from 'classnames';
import MessageIcon from '@material-ui/icons/Message';
import { getControlDataBySort, DELETE_MULTIPLE_ENTITY } from '../../../queries';
import CustomChipColored from '../../common/CustomChipColored';
import ControlDataProductModal from './ControlDataProductModal';

import { defineControlDataLevel } from '../../common/helper';

const styles = theme => ({
  chips: {
    marginRight: theme.spacing(0.5)
  },
  DisabledRow: {
    opacity: '.75',
    backgroundColor: 'rgba(0, 0, 0, .2)'
  },
  boxStyle: {
    backgroundColor: '#e0e0e0',
    fontSize: '0.8125rem',
    padding: '5px 8px',
    borderRadius: 16,
    display: 'inline-block',
    margin: '5px 5px 5px 0'
  },
  tooltip: {
    fontSize: 'inherit',
    minWidth: 400
  },
  tooltipNoBg: {
    background: 'none'
  },
  tooltipButton: {
    display: 'block',
    margin: '0 0 5px auto'
  },
  integration__status: {
    fontSize: '0.8125rem',
    padding: '3px 6px',
    borderRadius: 4,
    '&__0': {
      // lighter black
      color: 'rgba(0, 0, 0, .5)'
    },
    '&__2': {
      // light black
      color: 'rgba(0, 0, 0, .75)'
    },
    // green
    '&__1': {
      color: '#227e18'
    },
    '&__-1': {
      // red
      color: '#b71c1c'
    }
  },
  footer__item: {
    fontSize: '0.875rem',
    margin: '0 8px',
  },
});

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
};

const tableHeaders = [
  { name: 'sortString', label: 'Ohjaustieto', options: { display: false } },
  { name: 'levelString', label: 'Taso', options: { display: true, sort: true } },
  { name: 'idDescription', label: 'Tason kuvaus', options: { display: false, sort: true } },
  { name: 'sortStringOriginal', label: 'OhjaustietoOrg', options: { display: false } },
  { name: 'sortObject', options: { display: 'excluded', filter: false } },
  // { name: 'rulesCount', label: 'Ohjaussäännöt', options: { display: true, filter: false } },
  // { name: 'startDate', label: 'Alkuaika', options: { display: true, filter: true } },
  // { name: 'endDate', label: 'Loppuaika', options: { display: true, filter: true } },
  { name: 'userName', label: 'Tekijä', options: { filter: true } },
  { name: 'uniqueId', label: 'Yksilöivä id', options: { display: true, filter: false } },
  { name: 'comment', label: 'Kommentti', options: { display: true, filter: false } },
  { name: 'rules', label: 'Säännöt', options: { display: true, filter: false, sort: false } },
  { name: 'status', options: { display: 'excluded', filter: false } },
  { name: 'integrationStatus', label: 'Ohjaustiedon tila', options: { display: true, filter: true } },
  {
    name: 'lastUpdated',
    label: 'Muokattu',
    options: {
      display: true,
      filter: false,
      customBodyRender: (value) => <p>{new Date(value).toLocaleDateString('fi-FI', dateOptions)}</p>
    }
  },
  {
    name: 'more',
    label: ' ',
    options: { display: true, filter: false, sort: false, searchable: false, viewColumns: false }
  }
];

const ControlDataTable = props => {
  const {
    paper,
    handleModal,
    modalOpen,
    rowInfoData,
    loadingControlData,
    classes,
    selectedChain,
    querySortString,
    setModalAdditionalInfoHeading,
    refetchQuery,
    setHasControlData,
    targetStores,
  } = props;

  const alert = useAlert();
  const [ columns, setColumns ] = useState(tableHeaders);
  const [ productDialogOpen, setProductDialogOpen ] = useState(false);
  const [ productDialogData, setProductDialogData ] = useState({});
  const [ openTooltip, setOpenTooltip ] = useState('');
  const [ controlData, setControlDataItems ] = useState([]);
  const [ limit, setLimit ] = useState(25);

  const { data, loading, error, refetch, fetchMore } = useQuery(getControlDataBySort, {
    variables: {
      chain: _.isEmpty(selectedChain) ? '' : selectedChain.name,
      sort: querySortString === selectedChain.name ? null : querySortString, // if it is a chain level query, dont pass any sort string
      limit: limit === 0 ? null : limit,
    },
    fetchPolicy: 'cache-and-network',
    // pollInterval: 10000,
  });

  const handleComplete = () => {
    alert.success('Ohjaustieto poistettu.');
    refetch();
  };

  const handleError = error => {
    alert.error(`Virhe poistaessa ohjaustietoa: ${error}`);
  };

  const [ deleteEntities, { loading: loadingDeleteEntities } ] = useMutation(DELETE_MULTIPLE_ENTITY, {
    onCompleted: handleComplete,
    onError: handleError,
  });

  useEffect(() => {
    if (!_.isEmpty(_.get(data, 'queryControlData.Items'))) {
      setControlDataItems(_.get(data, 'queryControlData.Items'));
      setHasControlData(true);
    } else {
      setHasControlData(false);
    }
  }, [data]);

  useEffect(() => {
    if (refetchQuery) refetch();
  }, [refetchQuery]);

  const defineSortObject = (item) => {
    const split = item.split('#');
    const object = {
      chain: !_.isEqual(split[0], 'NA') ? split[0] : '',
      pob: !_.isEqual(split[2], 'NA') ? split[2] : '',
      campaign: !_.isEqual(split[4], 'NA') ? split[4] : '',
      brand: !_.isEqual(split[3], 'NA') ? split[3] : '',
      category: !_.isEqual(split[5], 'NA') ? split[5] : '',
      ean: !_.isEqual(split[6], 'NA') ? split[6] : ''
    };
    return object;
  };

  const handleMoreButton = (item, selection) => {
    const filteredSelection = selection
      .filter(x => x !== '')
      .map(x => <CustomChipColored key={x} value={x} bigText />);

    setModalAdditionalInfoHeading(filteredSelection);
    handleModal(!modalOpen);
    rowInfoData(item);
    setOpenTooltip('');
  };

  // const handleLimitChange = event => {
  //   setLimit(event.target.value);
  // };

  const tableOptions = {
    filter: true,
    print: false,
    download: false,
    filterType: 'dropdown',
    responsive: 'stacked',
    selectableRows: 'multiple',
    expandableRows: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [ 25, 50, 100 ],
    elevation: paper,
    searchPlaceholder: 'Hae tason kuvauksella, tekijällä tai yksilöivällä id:llä',
    setRowProps: row => {
      return {
        className: classnames({
          [classes.DisabledRow]: row[10] === 'disabled'
        })
      }
    },
    onRowsDelete: rowsDeleted => {
      try {
        const deletedIndexes = Object.keys(rowsDeleted.lookup);
        const deletedItems = [];

        deletedIndexes.map(x => deletedItems.push(controlData[x].sort));
        const deletedItemsAsString = deletedItems.join(',');

        const input = {
          id: 'OHJAUSTIETO',
          sortKeys: deletedItemsAsString,
        };
        // do the actual delete
        deleteEntities({
          variables: { input },
        })
        return true;
      } catch(err) {
        console.error('error: ', err);
        return false;
      }

    },
    onColumnViewChange: (changedColumn, display) => {
      const newCols = columns.slice();
      for (let ii = 0; ii < newCols.length; ii += 1) {
        if (newCols[ii].name === changedColumn) {
          newCols[ii].options.display = display === 'add';
        }
      }
      setColumns(newCols);
    },
    onColumnSortChange: (changedColumn, direction) => {
      let dir;
      if (direction === 'ascending') dir = 'asc';
      else dir = 'desc';
      const newCols = tableHeaders;
      for (let i = 0; i < newCols.length; i++) {
        delete newCols[i].options.sortDirection;
        if (newCols[i].name === changedColumn) {
          newCols[i].options.sortDirection = dir;
        }
      }
      setColumns(newCols);
    },
    customFooter: () => {
      const handleRowChange = event => {
        setLimit(event.target.value);
      };

      const handleLoad = () => {
        const { LastEvaluatedKey } = data.queryControlData;

        fetchMore({
          variables: { nextToken: LastEvaluatedKey },
          updateQuery: (_, { fetchMoreResult }) => {

            fetchMoreResult.queryControlData.Items = [
              ...fetchMoreResult.queryControlData.Items,
            ];

            return fetchMoreResult;

          },
        });
      };

      return (
        <TableBody>
          <TableRow>
            <TableCell style={{ textAlign: 'right' }}>
              <Typography component="span" className={classes.footer__item}>Ohjaustietoja sivulla: </Typography>
              <Select
                id="limit-select"
                value={limit}
                onChange={handleRowChange}
                className={classes.footer__item}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              {!_.isNull(_.get(data, 'queryControlData.Items')) && _.get(data, 'queryControlData.Items').length === limit && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => handleLoad()}
                  className={classes.footer__item}
                >
                  Seuraavat
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    },

    textLabels: {
      body: {
        noMatch: 'Ohjaustietoja ei löytynyt.',
        toolTip: 'Sort'
      },
      pagination: {
        next: 'Seuraava',
        previous: 'Edellinen',
        rowsPerPage: 'Ohjaustietoja sivulla:',
        displayRows: ' / '
      },
      toolbar: {
        search: 'Etsi',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät kolumnit',
        filterTable: 'Filteröi tuotteita'
      },
      filter: {
        all: 'Kaikki',
        title: 'Filteröi',
        reset: 'Nollaa'
      },
      viewColumns: {
        title: 'Näytä kolumneissa',
        titleAria: 'Näytä / Piilota'
      },
      selectedRows: {
        text: 'riviä valittu',
        delete: 'Poista',
        deleteAria: 'Poista valitut rivit'
      }
    }
  };

  if (loading || loadingDeleteEntities || loadingControlData) {
    return <CircularProgress />;
  }

  if (error) {
    alert.error(error);
    return <Typography>Error</Typography>;
  }

  const handleCloseProductModal = () => {
    setProductDialogOpen(false);
    setProductDialogData({});
  };

  const handleTooltipOpen = obj => {
    if (openTooltip === obj) {
      setOpenTooltip('');
    } else {
      setOpenTooltip(obj);
    }
  };

  const showTargetName = id => {
    if (_.isNil(id)) return;

    const targetName = targetStores.filter(x => x.id === id);

    if (_.isNil(targetName[0])) return id;

    return targetName[0].name;
  };


  const CustomTooltip = forwardRef((props, ref) => {
    const { sortString, sortObject, item, idDescription } = props;

    return (
      <ClickAwayListener ref={ref} onClickAway={() => setOpenTooltip('')}>
        <div>
          <Tooltip
            interactive
            open={openTooltip === sortString}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="left"
            classes={{ tooltip: classes.tooltipNoBg }}
            title={
              <Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.tooltipButton}
                  size="small"
                  onClick={() => handleMoreButton(item, [
                    sortObject.chain && `Ketju: ${sortObject.chain}`,
                    sortObject.pob && `Toimipiste: ${sortObject.pob}`,
                    sortObject.campaign && `Kampanja: ${sortObject.campaign}`,
                    sortObject.brand && `Brändi: ${sortObject.brand}`,
                    sortObject.category && `Kategoria: ${sortObject.category}`,
                    sortObject.ean && `EAN: ${sortObject.ean}`,
                    idDescription ? `Kuvaus: ${idDescription}` : ''
                  ])}
                >
                  Muokkaa ohjaustietoa
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.tooltipButton}
                  size="small"
                  onClick={() => {
                    setProductDialogOpen(true)
                    setProductDialogData(sortObject)
                    setOpenTooltip('')
                  }}
                >
                  Listaa tuotteet
                </Button>
              </Fragment>
            }
          >
            <IconButton onClick={() => handleTooltipOpen(sortString)}><MoreIcon style={{ fontSize: 22 }} /></IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  });

  const controlDataStatuses = {
    "0": "Ei ajettu",
    "2": "Ajo kohdejärjestelmään kesken",
    "1": "Ajo suoritettu",
    "-1": "Ajo keskeytyi virheeseen"
  };

  const renderRulesText = value => {
    if (!_.isNil(value) && value === 'true') {
      return 'Kyllä';
    }

    if (!_.isNil(value) && value === 'false') {
      return 'Ei';
    }

    if (!_.isNil(value) && value !== 'null') {
      return value;
    }

    return '-';
  };

  if (!_.isNull(data.queryControlData.Items)) {
    return (
      <Fragment>
        <MUIDataTable
          title="Ohjaustiedot"
          data={data.queryControlData.Items.map((item, i) => {

            const {
              userName,
              idDescription,
              sort,
            } = item;

            const rules = JSON.parse(item.rules);
            const sortStringOriginal = sort;
            const levelString = defineControlDataLevel(sort, item.uniqueId);
            const sortArray = item.sort.split('#');
            const finalArray = sortArray.filter((rule) => rule !== 'NA');
            const sortString = finalArray.join(' / ');
            const sortObject = defineSortObject(item.sort);

            return {
              chain: sortObject.chain,
              pob: sortObject.pob,
              campaign: sortObject.campaign,
              brand: sortObject.brand,
              category: sortObject.category,
              ean: sortObject.ean,
              uniqueId: item.uniqueId,
              levelString,
              idDescription,
              userName,
              comment: item.comment && item.comment !== 'Ei kommenttia' ? (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={item.comment}
                  placement="left"
                >
                  <IconButton><MessageIcon color="primary" /></IconButton>
                </Tooltip>
              ) : undefined,
              lastUpdated: rules.length > 0 ? item.lastUpdated : undefined,
              owner: item.owner,
              more: (
                <CustomTooltip
                  sortString={sortString}
                  item={item}
                  i={i}
                  sortObject={sortObject}
                  idDescription={idDescription}
                />
              ),
              rules: rules && (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    <>
                      <Box>ID: {renderRulesText(rules[0].id)}</Box>
                      <Box>Verkkonäkyvyys: {renderRulesText(rules[0].visibility)}</Box>
                      <Box>Myytävyys: {renderRulesText(rules[0].sellable)}</Box>
                      <Box>Toimitustapa: {renderRulesText(rules[0].deliveryMethod)}</Box>
                      <Box>Keräilymalli: {renderRulesText(rules[0].collectionModel)}</Box>
                      <Box>Saldoraja: {renderRulesText(rules[0].saldoLimit)}</Box>
                      <Box>Saldorajan kohde: {showTargetName(rules[0].saldoLimitTarget) || '-'}</Box>
                    </>
                  }
                  placement="left"
                >
                  <IconButton><MessageIcon color="primary" /></IconButton>
                </Tooltip>
              ),
              // startDate: rules.length > 0 ? new Date(rules[0].startDate).toLocaleString('fi-FI', dateOptions) : undefined,
              // endDate: rules.length > 0 ? new Date(rules[0].endDate).toLocaleString('fi-FI', dateOptions) : undefined,
              sortString,
              sortStringOriginal,
              rulesCount: rules.length,
              sortObject,
              status: item.status,
              integrationStatus: (
                <Box className={`${classes.integration__status} ${classes.integration__status}__${item.integrationStatus}`}>
                  {controlDataStatuses[item.integrationStatus]}
                </Box>
              ),
            };
          })}
          columns={columns}
          options={tableOptions}
        />
        {productDialogOpen && (
          <ControlDataProductModal
            productDialogOpen={productDialogOpen}
            handleCloseProductModal={handleCloseProductModal}
            productDialogData={productDialogData}
            selectedChain={selectedChain}
          />
        )}
      </Fragment>
    )
  } else {
    return (
      <MUIDataTable title="Ohjaustiedot" columns={columns} options={tableOptions} />
    )
  }
};

export default withStyles(styles)(ControlDataTable);
