import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Dialog,
  Grid,
  TextField,
  Button,
  withStyles,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  CircularProgress
} from '@material-ui/core';
// import { DateTimePicker } from '@material-ui/pickers';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
// import formatISO from 'date-fns/formatISO';

import SaveIcon from '@material-ui/icons/Save';
import MultiSelect from '../../common/MultiSelect';
import CustomChip from '../../common/CustomChip';
import TextArea from '../../common/TextArea';
import { getProductsQuery, createEntityForEans } from '../../../queries';

const styles = () => ({
  datePicker: {
    width: '100%'
  },
  saveIcon: {
    marginRight: 10
  },
  textarea: {
    width: '100%',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: 5
  }
});

const AddControlDataModal = props => {
  const {
    open,
    close,
    classes,
    selectedCollection,
    setCollection,
    selectedShippingMethod,
    setShippingMethod,
    // selectedStartDate,
    // setSelectedStartDate,
    // selectedEndDate,
    // setSelectedEndDate,
    visible,
    setVisible,
    sellable,
    setSellable,
    saldoLimit,
    setSaldoLimit,
    collection,
    shippingMethod,
    selectedChain,
    selectedEan,
    comment,
    setComment,
    inputComment,
    visibleOptions,
    sellableOptions,
    setSelectedEan,
    setRefetchQuery,
    setModalOpen,
    targetStores,
    selectedTargetStore,
    setSelectedTargetStore,
  } = props;

  const alert = useAlert();
  const eansArray = selectedEan.trim().split(' ');

  const filterQueryResults = data => {
    const selectedEansArray = selectedEan.split(' ');
    // compare really found results with given eans, return only the found
    const reallyFoundEans = data.searchProducts.data.filter(product => selectedEansArray.includes(product.ean));
    // map the found eans to array of just eans. also map id's (sok id)
    const justFoundEans = reallyFoundEans.map(item => item.ean);

    // filter out which given eans were not found
    const notFoundEans = selectedEansArray.filter(targetEan => !justFoundEans.includes(targetEan))

    return { found: reallyFoundEans, notFound: notFoundEans };
  }

  const renderEans = data => {
    if (data === undefined || !data.found.length) {
      return ( // if there is nothing found with the quer
        <>
          No products found matching given eans
        </>
      )
    }
    if (data.found.length > 0) {
      return (
        <>
          <Typography style={{ marginTop: 20 }}>EAN:it</Typography>
          {data.found.map((item) => <CustomChip key={item.id} value={`${item.ean} : ${item.name}`} />)}
          {!isEmpty(data.notFound) && (
            <Typography style={{ marginTop: 20 }}>Ei löytyneet EAN:it</Typography>
          )}
          {data.notFound.map((item) => <CustomChip key={item} value={`${item}`} />)}
        </>
      );
    }
    return (
      <>
        No products found matching given eans
      </>
    )
  };

  const queryWith = () => {
    const eanPart = selectedEan ? `ean:*${selectedEan}* ` : '';
    const queryString = eanPart;
    const splitted = queryString.split(',').join(' ') || ('*');
    console.log('using query: ', splitted);
    return splitted;
  };

  const checkInput = () => {
    if (selectedEan.length >= 1) {
      return false;
    }
    return true;
  };

  const { loading, error: errorProducts, data: dataProducts } = useQuery(getProductsQuery, {
    variables: {
      query: queryWith(),
      from: 0,
      size: 1000,
      structureCategories: isEmpty(selectedChain) ? '' : selectedChain.structureId,
    },
    skip: checkInput()
  });

  const handleEansComplete = () => {
    setModalOpen(false);
    sessionStorage.removeItem('controldata_ean');
    alert.success('Lisätty uusi ohjaustieto.');
  };

  const handleEansError = error => {
    setModalOpen(false);
    sessionStorage.removeItem('controldata_ean');
    alert.error(`${error}`);
  };

  const [ addControlDataMassEans, { loading: mutateLoading } ] = useMutation(
    createEntityForEans,
    {
      onCompleted: handleEansComplete,
      onError: handleEansError,
    }
  );

  const onClickSubmit = async data => {
    const newRule = {
      // startDate: formatISO(new Date(selectedStartDate)),
      // endDate: formatISO(new Date(selectedEndDate)),
      visibility: visible.id || 'null',
      sellable: sellable.id || 'null',
      saldoLimit: saldoLimit !== '' ? saldoLimit : undefined,
      deliveryMethod: selectedShippingMethod && selectedShippingMethod.name !== '' ? selectedShippingMethod.name : undefined,
      collectionModel: selectedCollection && selectedCollection.name !== '' ? selectedCollection.name : undefined,
      saldoLimitTarget: selectedTargetStore && selectedTargetStore.id !== '' ? selectedTargetStore.id : undefined,
    };

    const rules = [].concat(newRule);

    const selectedEansArray = selectedEan.split(' ');
    const reallyFoundEans = data.searchProducts.data.filter(product => selectedEansArray.includes(product.ean));
    const justFoundEans = reallyFoundEans.map(item => item.ean);
    const justFoundIds = reallyFoundEans.map(item => item.id);

    const eansString = justFoundEans.join(' ');
    const idsString = justFoundIds.join(' ');
    const isMassEan = justFoundEans.length > 1;

    const input = {
      id: "OHJAUSTIETO",
      chain: selectedChain.name,
      products: eansString,
      sokIds: idsString,
      rules: JSON.stringify(rules),
      ...(isMassEan
        ? { comment: 'ean csv upload' } // if mass
        : comment // is single
          ? { comment } // if has comment
          : null // if hasn't comment
      ),
    };

    console.log('saving control data: ', input);

    await addControlDataMassEans({
      variables: { input }
    });

    setSelectedEan('');
    setComment('');
    await setRefetchQuery(Date.now());
  };

  if (errorProducts) return alert.error(errorProducts);

  if (!open) // dont render anything if its not opened
    return '';

  if (loading) {
    return (
      <Dialog open={open} onClose={close} scroll="paper" maxWidth="xl">
        <DialogTitle>
          Uuden ohjaustiedon lisäys
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  } else {
    const filteredQueryResults = !isNil(dataProducts) ? filterQueryResults(dataProducts) : undefined;

    return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
        <DialogTitle>
          Uuden ohjaustiedon lisäys
          <div style={{ padding: '15px 0' }}>
            <CustomChip value={`Ketju: ${selectedChain.name}`} bigText />
            <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
              {renderEans(filteredQueryResults)}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {mutateLoading && <CircularProgress />}
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <DateTimePicker
                className={classes.datePicker}
                label="Alkuaika"
                value={selectedStartDate}
                onChange={setSelectedStartDate}
                format="d. MMMM, y, HH:mm"
                ampm={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                className={classes.datePicker}
                label="Loppuaika"
                value={selectedEndDate}
                onChange={setSelectedEndDate}
                format="d. MMMM, y, HH:mm"
                ampm={false}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <MultiSelect
                label="Verkkonäkyvyys"
                options={visibleOptions}
                selection={visible}
                setSelection={setVisible}
                multiple={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiSelect
                label="Myytävissä"
                options={sellableOptions}
                selection={sellable}
                setSelection={setSellable}
                multiple={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Saldoraja"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="0"
                value={saldoLimit}
                type="number"
                onChange={(event) => setSaldoLimit(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiSelect
                label="Saldorajan kohde"
                options={targetStores}
                selection={selectedTargetStore}
                setSelection={setSelectedTargetStore}
                multiple={false}
                disabled={isEmpty(saldoLimit)}
              />
            </Grid>
            {collection && size(collection) > 1 && (
              <Grid item xs={12} md={6}>
                <MultiSelect
                  label="Keräilymalli"
                  options={collection}
                  selection={selectedCollection}
                  setSelection={setCollection}
                  multiple={false}
                />
              </Grid>
            )}
            {shippingMethod && size(shippingMethod) > 1 && (
              <Grid item xs={12} md={6}>
                <MultiSelect
                  label="Toimitustapa"
                  options={shippingMethod}
                  selection={selectedShippingMethod}
                  setSelection={setShippingMethod}
                  multiple={false}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2}>
            {eansArray.length < 2 && (
              <Grid item xs={12} md={6}>
                <TextArea
                  inputComment={inputComment}
                  comment={comment}
                  setComment={setComment}
                />
              </Grid>
            )}
          </Grid>

          <DialogActions>
            <Button onClick={close} disabled={false} variant="contained" color="inherit" className={classes.button}>
              Peruuta
            </Button>
            {!isEmpty(get(dataProducts, 'searchProducts.data')) && (
              <Button
                onClick={() => onClickSubmit(dataProducts)}
                disabled={!isEmpty(saldoLimit) && isEmpty(get(selectedTargetStore, 'name'))}
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                <SaveIcon size="small" className={classes.saveIcon} />
                Tallenna
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

};

export default withStyles(styles)(AddControlDataModal);
