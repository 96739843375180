import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TokenAlive from './TokenAlive';

const getUrlParam = (url, param) => new URLSearchParams(url).get(param);

const logInUser = code => {
  const redirectUrlParam = getUrlParam(process.env.REACT_APP_LOGIN_URL, 'redirect_uri');
  const clientIdParam = getUrlParam(process.env.REACT_APP_LOGIN_URL, 'client_id');
  const tokenUrl = process.env.REACT_APP_TOKEN_URL;

  const postData = {
    grant_type: 'authorization_code',
    code,
    client_id: clientIdParam,
    redirect_uri: redirectUrlParam
  };

  let formBody = [];

  Object.keys(postData).forEach(key => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(postData[key]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  formBody = formBody.join('&');

  fetch(tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: formBody
  }).then(res => res.json())
    .then(data => {
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('id_token', data.id_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      window.location = '/';
    })
};

const logIn = () => {
  const codeParam = getUrlParam(window.location.search, 'code');

  return codeParam
    ? logInUser(codeParam)
    : <Redirect
        to={{
          pathname: '/login'
        }}
      />
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        TokenAlive()
          ? <Component {...props} />
          : logIn()
      }
    />
  );
};

export default AuthRoute;
