import React, { useContext } from 'react';
import { withStyles, Tooltip } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { storeNames } from '../../../data/ControlInfoOptions';

import { ProfileConsumer } from '../../../auth/ProfileProvider';

const drawerWidth = 240;

const styles = (theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    marginLeft: drawerWidth
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

const pathTitleMap = {
  '/': 'Ohjaustiedot',
  '/tuotteet': 'Tuotteet',
  '/tapahtumat': 'Tapahtumat'
};

const TopBar = (props) => {
  const current = () => {
    const { location } = props;
    return pathTitleMap[location.pathname];
  };
  const modifiedStoreNames = (profileChain) => {
    let modifiedNames = '';
    let modifiedNameSplit = profileChain.split(',');
    let modifiedArray = [];
    modifiedNameSplit.forEach(item => {
      modifiedArray.push(storeNames[item].name);
    });
    modifiedNames = modifiedArray.join(', ');
    return modifiedNames;
  };
  const { classes, handleToggle } = props;
  const profile = useContext(ProfileConsumer);
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton color="inherit" aria-label="Open drawer" onClick={handleToggle} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Typography className={classes.grow} variant="h6" color="inherit" noWrap>
          {current()}
        </Typography>
        <Tooltip title="Käyttäjänimi">
          <Typography variant="h6" color="inherit" noWrap>
            {profile.username}
          </Typography>
        </Tooltip>

        <Tooltip title="Ketju">
          <Typography variant="h6" color="inherit" noWrap>
            <span>&nbsp; / </span>
            {modifiedStoreNames(profile.chain)}
          </Typography>
        </Tooltip>
        <Tooltip title="Kirjaudu Ulos">
          <IconButton color="inherit" component={Link} to="/logout" aria-label="Logout">
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(withStyles(styles)(TopBar));
