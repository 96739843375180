import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fiLocale from 'date-fns/locale/fi';
import DateFnsUtils from '@date-io/date-fns';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import App from './App';
import * as serviceWorker from './serviceWorker';
import kuukkeliApolloClient from './kuukkeliApolloClient';

const isTokenUndefined = token => localStorage.getItem(token) === 'undefined' || isNil(localStorage.getItem(token)) || isEmpty(localStorage.getItem(token));

if (isTokenUndefined('access_token') || isTokenUndefined('id_token') || isTokenUndefined('refresh_token')) {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
}

ReactDOM.render(
  <ApolloProvider client={kuukkeliApolloClient}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
      <App />
    </MuiPickersUtilsProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
