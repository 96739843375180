import React from 'react';
import {
  Dialog,
  Grid,
  TextField,
  Button,
  withStyles,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
// import { DateTimePicker } from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import MultiSelect from '../../common/MultiSelect';
import CustomChip from '../../common/CustomChip';
import TextArea from '../../common/TextArea';

const styles = () => ({
  datePicker: {
    width: '100%'
  },
  saveIcon: {
    marginRight: 10
  },
  textarea: {
    width: '100%',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: 5
  }
});

const AddControlDataModal = props => {
  const {
    open,
    close,
    classes,
    selectedCollection,
    setCollection,
    selectedShippingMethod,
    setShippingMethod,
    // selectedStartDate,
    // setSelectedStartDate,
    // selectedEndDate,
    // setSelectedEndDate,
    visible,
    setVisible,
    sellable,
    setSellable,
    saldoLimit,
    setSaldoLimit,
    collection,
    shippingMethod,
    onClickSubmit,
    selectectedChain,
    selectedCoop,
    // selectedUnits,
    selectedCampaign,
    selectedBrands,
    selectedCategories,
    comment,
    setComment,
    inputComment,
    visibleOptions,
    sellableOptions
  } = props;

  return (
    <Dialog open={open} onClose={close} scroll="paper" maxWidth="xl">
      <DialogTitle>
        Uuden ohjaustiedon lisäys
        <div style={{ padding: '15px 0' }}>
          <CustomChip value={`Ketju: ${selectectedChain}`} bigText />
          {selectedCoop !== ''
            ? <CustomChip value={`AOK: ${selectedCoop}`} bigText />
            : ''
          }
          {/* {selectedUnits.length !== 0
            ? <CustomChip value={`Toimipiste: ${selectedUnits.map((unit) => unit.name)}`} bigText />
            : ''
          } */}
          {selectedCampaign.length !== 0
            ? <CustomChip value={`Kampanja: ${selectedCampaign.map((campaign) => campaign.name)}`} bigText />
            : ''
          }
          {selectedBrands.length !== 0
            ? <CustomChip value={`Brändi: ${selectedBrands.map((brand) => brand.name)}`} bigText />
            : ''
          }
          {selectedCategories.length !== 0
            ? <CustomChip value={`Kategoria: ${selectedCategories.map((category) => `${category.id} / ${category.name}`)}`} bigText />
            : ''
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <DateTimePicker
              className={classes.datePicker}
              label="Alkuaika"
              value={selectedStartDate}
              onChange={setSelectedStartDate}
              format="d. MMMM, y, HH:mm"
              ampm={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateTimePicker
              className={classes.datePicker}
              label="Loppuaika"
              value={selectedEndDate}
              onChange={setSelectedEndDate}
              format="d. MMMM, y, HH:mm"
              ampm={false}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <MultiSelect
              label="Verkkonäkyvyys"
              options={visibleOptions}
              selection={visible}
              setSelection={setVisible}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultiSelect
              label="Myytävissä"
              options={sellableOptions}
              selection={sellable}
              setSelection={setSellable}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Saldoraja"
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="0"
              value={saldoLimit}
              type="number"
              onChange={(event) => setSaldoLimit(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultiSelect
              label="Keräilymalli"
              options={collection}
              selection={selectedCollection}
              setSelection={setCollection}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultiSelect
              label="Toimitustapa"
              options={shippingMethod}
              selection={selectedShippingMethod}
              setSelection={setShippingMethod}
              multiple={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextArea
              inputComment={inputComment}
              comment={comment}
              setComment={setComment}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={close} disabled={false} variant="contained" color="inherit" className={classes.button}>
            Peruuta
          </Button>
          <Button
            onClick={onClickSubmit}
            disabled={false}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            <SaveIcon size="small" className={classes.saveIcon} />
            Tallenna
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AddControlDataModal);
