import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const TokenAlive = () => {
  const isTokenUndefined = token => localStorage.getItem(token) === 'undefined' || isNil(localStorage.getItem(token)) || isEmpty(localStorage.getItem(token));

  if (isTokenUndefined('access_token') || isTokenUndefined('id_token') || isTokenUndefined('refresh_token')) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    return false;
  }

  return true;
};

export default TokenAlive;
