import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useQuery } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import { getSystemEvents } from '../../../queries';

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
};

const systemEventColumns = [
  { name: 'message', label: 'Viesti' },
  {
    name: 'timestamp',
    label: 'Aika',
    options: {
      display: true,
      filter: true,
      customBodyRender: (value) => <p>{new Date(value).toLocaleDateString('fi-FI', dateOptions)}</p>,
      sortDirection: 'desc',
    }
  },
  { name: 'type', label: 'Tyyppi' }
];

const options = {
  filterType: 'dropdown',
  filter: true,
  print: false,
  download: true,
  selectableRows: 'none', // <===== will turn off checkboxes in rows
  expandableRows: false,
  responsive: 'stacked',
  rowsPerPage: 25,
  rowsPerPageOptions: [ 25, 50, 100 ],
  textLabels: {
    body: {
      noMatch: 'Ei näytettävää.',
      toolTip: 'Lajittele'
    },
    pagination: {
      next: 'Seuraava',
      previous: 'Edellinen',
      rowsPerPage: 'Tapahtumia sivulla:',
      displayRows: '/'
    },
    toolbar: {
      search: 'Etsi',
      downloadCsv: 'Lataa CSV',
      print: 'Tulosta',
      viewColumns: 'Näytettävät kolumnit',
      filterTable: 'Filteröi tapahtumia'
    },
    filter: {
      all: 'Kaikki',
      title: 'Filteröi',
      reset: 'Nollaa'
    },
    viewColumns: {
      title: 'Näytä kolumneissa',
      titleAria: 'Näytä / Piilota'
    },
    selectedRows: {
      text: 'riviä valittu',
      delete: 'Poista',
      deleteAria: 'Poista valitut rivit'
    }
  }
};

const SystemEventsTable = () => {
  const { loading, error, data } = useQuery(getSystemEvents, { fetchPolicy: "cache-first" });

  if (loading) {
    return (
      <main>
        <CircularProgress />
      </main>
    );
  }

  if (error) return 'error';

  const { querySystemEventData } = data;

  return (
    <MUIDataTable
      title="Systeemitapahtumat"
      columns={systemEventColumns}
      data={querySystemEventData.Items.map((item) => {
        return {
          responsible: item.userName,
          id: item.id,
          timestamp: item.timestamp,
          type: item.action,
          message: item.message
        };
      })}
      options={options}
    />
  );

};

export default SystemEventsTable;
