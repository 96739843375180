import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withStyles, CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import { getProductsQuery } from '../../../queries';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    marginBottom: theme.spacing(1),
    flexGrow: 1
  },
  table: {
    margin: theme.spacing(1)
  },
  searchElement: {
    margin: theme.spacing(2)
  },
  tableRow: {
    hover: {
      cursor: 'pointer'
    }
  },
  uploadButton: {
    margin: theme.spacing(1)
  },
  uploadIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 20
  }
});
const tableHeaders = [
  { name: 'id', options: { display: 'excluded', filter: false } },
  { name: 'name', label: 'Nimi', options: { filter: true } },
  { name: 'brandName', label: 'Brändi', options: { filter: true } },
  { name: 'ean', label: 'EAN', options: { filter: true } }
];

const query = (name, ean, brandName) => {
  // const queryString = `name:*${name}* ean:*${ean}* brandName:*${brandName}*`;
  const namePart = name ? `name:*${name}*` : '';
  const eanPart = ean ? `ean:*${ean}*` : '';
  const brandPart = brandName ? `brandName:*${brandName}*` : '';
  const queryString = namePart + eanPart + brandPart;
  console.log(queryString);
};

const ProductTable = props => {
  const { name, ean, brandName, selectedProduct, closeDrawer } = props;
  const tableOptions = {
    filter: true,
    print: false,
    download: false,
    filterType: 'dropdown',
    responsive: 'stacked',
    selectableRows: false,
    expandableRows: false,
    rowsPerPage: 25,
    elevation: 1,
    rowsPerPageOptions: [ 25, 50, 100 ],
    onRowClick: (rowData) => {
      selectedProduct(rowData[0]);
      closeDrawer();
    },
    textLabels: {
      body: {
        noMatch: 'Hae ensin tuotteita.',
        toolTip: 'Sort'
      },
      pagination: {
        next: 'Seuraava',
        previous: 'Edellinen',
        rowsPerPage: 'Tuotteita sivulla:',
        displayRows: 'of'
      },
      toolbar: {
        search: 'Etsi',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät kolumnit',
        filterTable: 'Filteröi tuotteita'
      },
      filter: {
        all: 'Kaikki',
        title: 'Filteröi',
        reset: 'Nollaa'
      },
      viewColumns: {
        title: 'Näytä kolumneissa',
        titleAria: 'Näytä / Piilota'
      },
      selectedRows: {
        text: 'riviä valittu',
        delete: 'Poista',
        deleteAria: 'Poista valitut rivit'
      }
    }
  };

  const checkInput = () => {
    if (name.length > 2) {
      return false;
    }
    if (ean.length > 2) {
      return false;
    }
    if (brandName.length > 2) {
      return false;
    }
    return true;
  };

  const { loading, error, data } = useQuery(getProductsQuery, {
    variables: {
      query: query(name, ean, brandName),
      from: 0,
      size: 100,
      controlDataRelations: true
    },
    skip: checkInput()
  });

  if (loading) return <CircularProgress />;
  if (error) return 'error';
  if (!data) return <MUIDataTable title="Tuotteet" data={[]} columns={tableHeaders} options={tableOptions} />;

  return (
    <MUIDataTable
      title="Tuotteet"
      data={data.searchProducts.data}
      columns={tableHeaders}
      options={tableOptions}
    />
  );

};

export default withStyles(styles)(ProductTable);
