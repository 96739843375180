import { ApolloLink } from 'apollo-boost';

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      'x-api-key': 'da2-b352u3nzrralzewabaclbuzwfq',
      authorization: localStorage.getItem('access_token')
    }
  });

  return forward(operation);
});

export default authMiddleware;
