import React, { useState } from 'react';
import { Tooltip, IconButton, withStyles, ClickAwayListener } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const styles = () => ({
  tooltip: {
    fontSize: 'inherit',
    minWidth: 400
  }
});

const InfoToolTip = ({ text, classes }) => {
  const [ open, setOpen ] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (open) setOpen(!true);
    else setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<div style={{ padding: '0 10px' }}>{text}</div>}
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton onClick={handleTooltipOpen}><InfoIcon color="primary" /></IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
};

export default withStyles(styles)(InfoToolTip);
