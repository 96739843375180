import React, { useState, useEffect, createRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withStyles, CircularProgress, Typography, IconButton } from '@material-ui/core';
import { useAlert } from 'react-alert';
import MUIDataTable from 'mui-datatables';
import _ from 'lodash';
import MoreIcon from '@material-ui/icons/MoreVert';

import ProductInfoModal from './ProductInfoModal';
import { getProductsQuery, getChains, GET_CONTROLDATA_BY_ID } from '../../../queries';
import ProductSearch from './ProductSearch';
import CategoryModal from '../../common/CategoryModal';
import CustomChip from '../../common/CustomChip';
import ProductDataModal from './ProductDataModal';
import CustomChipColored from '../../common/CustomChipColored';

import { defineControlDataLevelAsText, createCategoryChip } from '../../common/helper';
import { collectionOptions, shippingMethodOptions, visibleOptions, sellableOptions, targetStoreOptions  } from '../../../data/ControlInfoOptions';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    marginBottom: theme.spacing(1),
    flexGrow: 1,
    zIndex: -1
  },
  table: {
    zIndex: -1
  },
  searchElement: {
    margin: theme.spacing(2)
  },
  tableRow: {
    hover: {
      cursor: 'pointer'
    }
  },
  uploadButton: {
    margin: theme.spacing(1)
  },
  uploadIcon: {
    marginLeft: theme.spacing(2),
    fontSize: 20
  },
  chip: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  tooltip: {
    fontSize: 'inherit',
    minWidth: 400
  }
});

const queryCategories = selectedCategories => {
  const category = selectedCategories[0] ? selectedCategories[0].id : '*';
  return category;
};

const rememberSelection = type => JSON.parse(sessionStorage.getItem(type));

const initAccurateSearch = [{ name: "name", checked: false }, { name: "campaign", checked: false }, { name: "brand", checked: false }];

const ProductList = props => {
  // states
  const [ open, setOpen ] = useState(false);
  const [ productData, setProductData ] = useState({});
  const [ name, setName ] = useState(rememberSelection("products_name") || '');
  const [ inputName, setInputName ] = useState('');
  const [ ean, setEan ] = useState(rememberSelection("products_ean") || '');
  const [ inputEan, setInputEan ] = useState('');
  const [ selectedChain, setChain ] = useState(rememberSelection("products_chain") || {});
  const [ selectedBrands, setBrands ] = useState(rememberSelection("products_brand") || []);
  const [ categoryModalOpen, setCategoryModalOpen ] = useState(false);
  const [ selectedCategories, setCategories ] = useState(rememberSelection("products_category") || []);
  const { loading: loadingChains, error: errorChains, data: dataChains } = useQuery(getChains);
  const [ chains, setChains ] = useState([]);
  const [ categoryTree, setCagoryTree ] = useState('');
  const [ accurateSearch, setAccurateSearch ] = useState(rememberSelection("products_checked") || initAccurateSearch);
  const [ selectedCategoryTree, setSelectedCategoryTree ] = useState('');
  const [ selectedCampaign, setCampaign ] = useState(rememberSelection("products_campaign") || []);
  const [ columns, setColumns ] = useState([]);
  const [ includeControlDataRelations, setIncludeControlDataRelations ] = useState(false);
  const [ modifyControlData, setModifyControlData ] = useState({});
  const [ comment, setComment ] = useState('');
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ selectedTargetStore, setSelectedTargetStore ] = useState('');

  const chainName = selectedChain.name;

  const inputComment = createRef();

  const handleMoreButton = item => {
    setProductData(item)
    setOpen(true);
  };

  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const getDate = date => {
    if (_.isUndefined(date)) return false;

    return date.map(x => new Date(x).toLocaleString('fi-FI', dateOptions));
  }

  const getControlDataTypeColor = value => {
    if (_.includes(value, 'Kampanja')) return 'campaign';
    if (_.includes(value, 'Brändi')) return 'brand';
    if (_.includes(value, 'Kategoria')) return 'category';
    if (_.includes(value, 'EAN')) return 'ean';
  }

  const tableColumns = [
    { name: 'id', label: 'ID', options: { download: false, display: false, filter: false } },
    { name: 'ean', label: 'EAN', options: { download: true, filter: true } },
    { name: 'name', label: 'Nimi', options: { download: true, filter: true } },
    { name: 'name25', label: 'Pitkä nimi', options: { download: false, display: false, filter: true } },
    { name: 'description', label: 'Kuvaus', options: { download: false, display: false, filter: true } },
    { name: 'brandId', label: 'BrändiId', options: { download: false, filter: true } },
    { name: 'brandName', label: 'Brändi', options: { download: false, filter: true } },
    { name: 'brandParent', label: 'Brändi parent', options: { download: false, display: false, filter: true } },
    { name: 'ecomPublish', label: 'Status eCom', options: { download: false, display: true, filter: true } },
    {
      name: 'campaign',
      label: 'Kampanja',
      options: {
        download: false,
        filter: true,
        customBodyRender: value => (
          value
            .filter(camp => camp !== null)
            .map(campaign => <CustomChip key={campaign.id} value={`Id: ${campaign.id}`} />)
        )
      }
    },
    {
      name: 'categories',
      label: 'Kategoriat',
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: value => (
          value.filter(cat => cat !== null)
            .sort((a, b) => a.level < b.level)
            .map(category => createCategoryChip(category.structureId, category.id, category.translations, category.level))
        )
      }
    },
    {
      name: 'controlDataRelations',
      label: 'Liittyvät ohjaustiedot',
      options: {
        download: true,
        display: false,
        filter: true,
        sort: false,
        viewColumns: includeControlDataRelations || false,
        customBodyRender: value => (
          value
            ? value.map((x, i) => <CustomChipColored key={i} value={x} type={getControlDataTypeColor(x)} />)
            : null
        )
      }
    },
    {
      name: 'comment',
      label: 'Kommentti',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      }
    },
    {
      name: 'visibility',
      label: 'Verkkonäkyvyys',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? _.flatten(value).map((x, i) => x === true ? <p key={i}>Kyllä</p> : <p key={i}>Ei</p>)
        //     : null
        // )
      }
    },
    {
      name: 'sellable',
      label: 'Myytävissä',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? _.flatten(value).map((x, i) => x === true ? <p key={i}>Kyllä</p> : <p key={i}>Ei</p>)
        //     : null
        // )
      }
    },
    {
      name: 'deliveryMethod',
      label: 'Toimitustapa',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? value.map((x, i) => <p key={i}>{x}</p>)
        //     : null
        // )
      }
    },
    {
      name: 'collectionModel',
      label: 'Keräilymalli',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? value.map((x, i) => <p key={i}>{x}</p>)
        //     : null
        // )
      }
    },
    {
      name: 'saldoLimit',
      label: 'Saldoraja',
      options: {
        download: true,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? value.map((x, i) => <p key={i}>{x}</p>)
        //     : null
        // )
      }
    },
    {
      name: 'startDate',
      label: 'Alkuaika',
      options: {
        download: false,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? value.map((x, i) => <p key={i}>{x}</p>)
        //     : null
        // )
      }
    },
    {
      name: 'endDate',
      label: 'Loppuaika',
      options: {
        download: false,
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        // customBodyRender: value => (
        //   value
        //     ? value.map((x, i) => <p key={i}>{x}</p>)
        //     : null
        // )
      }
    },
    {
      name: 'more',
      label: ' ',
      options: {
        download: false,
        display: true,
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: value => (
          <IconButton onClick={() => handleMoreButton(value)} style={{ padding: 8 }}>
            <MoreIcon style={{ fontSize: 22 }} />
          </IconButton>
        )
      }
    }
  ];

  useEffect(() => {
    setColumns(tableColumns);
  }, []);

  const queryWith = () => {
    let namePart = inputName ? `name:*${inputName}* ` : '';
    let brandPart = !_.isEmpty(selectedBrands) ? `brand:*${selectedBrands.map(b => b.id)}* ` : '';
    let campaignPart = !_.isEmpty(selectedCampaign) ? `campaigns:*${selectedCampaign.map(b => b.id)}* ` : '';
    if (accurateSearch[0].checked) namePart = inputName ? `name:${inputName} ` : '';
    if (accurateSearch[1].checked) campaignPart = !_.isEmpty(selectedCampaign) ? `campaigns:${selectedCampaign.map(b => b.id)} ` : '';
    if (accurateSearch[2].checked) brandPart = !_.isEmpty(selectedBrands) ? `brand:${selectedBrands.map(b => b.name)} ` : '';

    const eanPart = inputEan ? `ean:*${inputEan}* ` : '';
    const queryString = namePart + eanPart + brandPart + campaignPart;
    const splitted = queryString.split(',').join(' ') || ('*');
    return splitted;
  };

  const checkInput = () => {
    if (inputName.length > 2) {
      return false;
    }
    if (inputEan.length > 2) {
      return false;
    }
    if (selectedBrands.length >= 1) {
      return false;
    }
    if (selectedCategories.length >= 1) {
      return false;
    }
    if (selectedCampaign.length >= 1) {
      return false;
    }
    return true;
  };

  const { loading, error, data } = useQuery(getProductsQuery, {
    variables: {
      query: queryWith(),
      category: queryCategories(selectedCategories),
      structureCategories: _.isEmpty(selectedChain) ? '' : selectedChain.structureId,
      from: 0,
      size: 1000,
      controlDataRelations: includeControlDataRelations,
    },
    skip: checkInput()
  });

  const alert = useAlert();

  const handleAccurateSearchChange = itemName => e => {
    const checkedItems = accurateSearch.map(x => x.name === itemName ? { ...x, name: itemName, checked: e.target.checked } : x);
    sessionStorage.setItem('products_checked', JSON.stringify(checkedItems));
    setAccurateSearch(checkedItems);
  };

  const resetStorage = () => {
    const sessionStorageItems = [
      'products_name',
      'products_ean',
      'products_chain',
      'products_brand',
      'products_category',
      'products_campaign',
      'products_checked',
      'products_connections'
    ];

    sessionStorageItems.forEach(item => {
      sessionStorage.removeItem(item);
    });
  };

  useEffect(() => {
    const CDTChain = [ 'CDT' ];

    if (_.get(dataChains, 'getAllChains')) {
      const chainIds = dataChains.getAllChains.map((x) => x.structureId);
      const updatedChainIds = CDTChain.concat(chainIds);
      setChains(updatedChainIds);
    }

  }, [dataChains]);

  if (loadingChains) {
    return <CircularProgress />;
  }

  if (errorChains) {
    return alert.error(errorChains);
  }

  const { classes } = props;

  const renderCsvText = value => {
    if (!_.isNil(value) && value[0] === 'true') {
      return 'Kyllä';
    }

    if (!_.isNil(value) && value[0] === 'false') {
      return 'Ei';
    }

    if (!_.isNil(value) && value[0] !== 'null') {
      return value;
    }

    return ' ';
  };

  const tableOptions = {
    responsive: 'stacked',
    filter: true,
    print: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      let objIndex = -1;
      const array = [];

      data.map(x => {
        const controlDataRelations = x.data[10].filter(x => x.structureId === selectedChain.structureId);

        if (!_.isEmpty(controlDataRelations)) {
          return _.forEach(controlDataRelations, (value, index) => {
            console.log(value, index);
            const dataRow = _.dropRight(x.data, 10);
            const updatedDataRow = {
              data: [
                ...dataRow,
                dataRow[11] = renderCsvText(x.data[11][index]),   // ean
                dataRow[12] = renderCsvText(x.data[12][index]),   // nimi
                dataRow[13] = renderCsvText(x.data[13][index]),   // liittyvät ohjaustiedot
                dataRow[14] = renderCsvText(x.data[14][index]),   // kommentti
                dataRow[15] = renderCsvText(x.data[15][index]),   // verkkonäkyvyys
                dataRow[16] = renderCsvText(x.data[16][index]),   // myytävissä
                dataRow[17] = renderCsvText(x.data[17][index]),   // toimitustapa
                dataRow[18] = renderCsvText(x.data[18][index]),   // keräilymalli
                dataRow[19] = renderCsvText(x.data[19][index]),   // saldoraja
              ],
              index: objIndex += 1
            };

            return array.push(updatedDataRow);
          });
        }

        return array.push(x);
      });

      return buildHead(columns) + buildBody(array);
    },
    selectableRows: 'none',
    filterType: 'dropdown',
    elevation: 1,
    expandableRows: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [ 25, 50, 100 ],
    searchPlaceholder: 'Hae tuotteen nimellä, EAN:lla tai brändillä',
    onColumnSortChange: (changedColumn, direction) => {
      let dir;
      if (direction === 'ascending') dir = 'asc';
      else dir = 'desc';
      const newCols = tableColumns;
      for (let i = 0; i < newCols.length; i++) {
        delete newCols[i].options.sortDirection;
        if (newCols[i].name === changedColumn) {
          newCols[i].options.sortDirection = dir;
        }
      }
      setColumns(newCols);
    },
    textLabels: {
      body: {
        noMatch: 'Hae ensin tuotteita.',
        toolTip: 'Sort'
      },
      pagination: {
        next: 'Seuraava',
        previous: 'Edellinen',
        rowsPerPage: 'Tuotteita sivulla:',
        displayRows: 'of'
      },
      toolbar: {
        search: 'Etsi',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät kolumnit',
        filterTable: 'Filteröi tuotteita'
      },
      filter: {
        all: 'Kaikki',
        title: 'Filteröi',
        reset: 'Nollaa'
      },
      viewColumns: {
        title: 'Näytä kolumneissa',
        titleAria: 'Näytä / Piilota'
      },
      selectedRows: {
        text: 'EANia valittu',
        delete: 'Lisää ohjaussääntö valituille EANeille',
        deleteAria: 'Lisää ohjaussääntö valituille EANeille'
      }
    }
  };

  const productHasControlData = (relation, type) => {
    const item = relation.map(x => {
      const parsed = JSON.parse(x.rules);
      const obj = parsed.map(y => y[type]);

      if (_.isNil(obj[0])) return null;

      return obj;
    });

    return item;
  };

  const getRelations = relations => relations.map(x => defineControlDataLevelAsText(x.sort));

  const renderProducts = () => {
    if (loading) return <CircularProgress />;
    if (error) {
      console.error(error);
      return <Typography>Haussa tapahtui virhe: {error.message}</Typography>;
    }

    if (!data) return <MUIDataTable title="Tuotteet" data={[]} columns={columns} options={tableOptions} />;

    return (
      <MUIDataTable
        title="Tuotteet"
        data={data.searchProducts.data.map(item => {
          return {
            id: item.id,
            brandId: item.brand,
            brandName: item.brandName,
            brandParent: item.brandParent,
            campaign: item.campaigns,
            ean: item.ean,
            name: item.name,
            name25: item.name25,
            description: item.description,
            categories: item.categories,
            ecomPublish: item.ecomPublish,
            controlDataRelations: item.controlDataRelations && getRelations(item.controlDataRelations),
            comment: item.controlDataRelations && item.controlDataRelations.map(x => x.comment),
            visibility: item.controlDataRelations && productHasControlData(item.controlDataRelations, 'visibility'),
            sellable: item.controlDataRelations && productHasControlData(item.controlDataRelations, 'sellable'),
            saldoLimit: item.controlDataRelations && productHasControlData(item.controlDataRelations, 'saldoLimit'),
            collectionModel: item.controlDataRelations && productHasControlData(item.controlDataRelations, 'collectionModel'),
            deliveryMethod: item.controlDataRelations && productHasControlData(item.controlDataRelations, 'deliveryMethod'),
            startDate: item.controlDataRelations && getDate(productHasControlData(item.controlDataRelations, 'startDate')),
            endDate: item.controlDataRelations && getDate(productHasControlData(item.controlDataRelations, 'endDate')),
            more: item
          };
        })}
        columns={columns}
        options={tableOptions}
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePropsReturn = (mySetFunction) => (value) => {
    // console.log('wut_value ', ' ', value);

    mySetFunction(value);
  };

  const handleCategoryOpen = (chain) => {
    setCagoryTree(chain);
    setCategoryModalOpen(true);
    setSelectedCategoryTree(chain);
  };

  const handleCategoryClose = () => {
    setCagoryTree('');
    setCategoryModalOpen(false);
  };

  const resetSelection = () => {
    resetStorage();
    setChain({});
    setName('');
    setEan('');
    setBrands([]);
    setCategories([]);
    setSelectedCategoryTree('');
    setCampaign([]);
    setAccurateSearch(initAccurateSearch);
    setInputName('');
    setInputEan('');
    setSelectedTargetStore('');
  };

  const defineSortObject = (item) => {
    const split = item.split('#');
    const object = {
      chain: !_.isEqual(split[0], 'NA') ? split[0] : '',
      pob: !_.isEqual(split[2], 'NA') ? split[2] : '',
      campaign: !_.isEqual(split[4], 'NA') ? split[4] : '',
      brand: !_.isEqual(split[3], 'NA') ? split[3] : '',
      category: !_.isEqual(split[5], 'NA') ? split[5] : '',
      ean: !_.isEqual(split[6], 'NA') ? split[6] : ''
    };
    return object;
  };

  const sortObject = _.get(modifyControlData, 'sort') && defineSortObject(modifyControlData.sort);

  const modalAdditionalInfoHeading = _.get(modifyControlData, 'sort') && [
    sortObject.chain && `Ketju: ${sortObject.chain}`,
    sortObject.pob && `Toimipiste: ${sortObject.pob}`,
    sortObject.campaign && `Kampanja: ${sortObject.campaign}`,
    sortObject.brand && `Brändi: ${sortObject.brand}`,
    sortObject.category && `Kategoria: ${sortObject.category}`,
    sortObject.ean && `EAN: ${sortObject.ean}`,
    modifyControlData.idDescription ? `Kuvaus: ${modifyControlData.idDescription}` : ''
  ]
    .filter(x => x !== '')
    .map(x => <CustomChipColored key={x} value={x} bigText />);

  return (
    <>
      <ProductSearch
        name={name}
        ean={ean}
        selectedBrands={selectedBrands}
        selectedCategories={selectedCategories}
        selectedChain={selectedChain}
        chains={chains}
        chooseChain={handleCategoryOpen}
        resetSelection={resetSelection}
        accurateSearch={accurateSearch}
        setAccurateSearch={handleAccurateSearchChange}
        setChain={setChain}
        setName={handlePropsReturn(setName)}
        setEan={handlePropsReturn(setEan)}
        setBrands={handlePropsReturn(setBrands)}
        setCategories={handlePropsReturn(setCategories)}
        selectedCategoryTree={selectedCategoryTree}
        selectedCampaign={selectedCampaign}
        setCampaign={setCampaign}
        includeControlDataRelations={includeControlDataRelations}
        setIncludeControlDataRelations={setIncludeControlDataRelations}
        setInputName={setInputName}
        setInputEan={setInputEan}
      />
      {categoryTree && (
        <CategoryModal
          open={categoryModalOpen}
          onClose={handleCategoryClose}
          setCategories={setCategories}
          structureId={categoryTree}
          remember="products_category"
        />
      )}
      <ProductInfoModal
        open={open}
        close={handleClose}
        productData={productData}
        setModifyControlData={setModifyControlData}
        openModal={setModalOpen}
        includeControlDataRelations={includeControlDataRelations}
      />
      {!_.isEmpty(modifyControlData) && (
        <ProductDataModal
          modalOpen={modalOpen}
          handleModal={setModalOpen}
          modalAdditionalInfoHeading={modalAdditionalInfoHeading}
          rowInfoData={modifyControlData}
          selectedChain={selectedChain.name}
          collection={collectionOptions[chainName]}
          shippingMethod={shippingMethodOptions[chainName]}
          inputComment={inputComment}
          newComment={comment}
          setComment={setComment}
          query={GET_CONTROLDATA_BY_ID}
          visibleOptions={visibleOptions}
          sellableOptions={sellableOptions}
          targetStores={targetStoreOptions[chainName]}
          selectedTargetStore={selectedTargetStore}
          setSelectedTargetStore={setSelectedTargetStore}
          productData={productData}
        />
      )}
      <div className={classes.table}>{renderProducts()}</div>
    </>
  );
};

export default withStyles(styles)(ProductList);
